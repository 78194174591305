// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-fb {
  background-color: #3b5998;
  color: white;
  width: fit-content;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bs-fb>.fa {
  margin-left: 1px;
  display: block;
  font-size: 30px;
}
.bs-fb>* {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/facebook.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-fb {\n  background-color: #3b5998;\n  color: white;\n  width: fit-content;\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.bs-fb>.fa {\n  margin-left: 1px;\n  display: block;\n  font-size: 30px;\n}\n.bs-fb>* {\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
