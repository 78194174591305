// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-forgot-password{
  height: 100%;
  min-height: 257px;
}

.bs-forgot-password > .bs-content {
  width: 370px;
  padding: 12px;
}
.bs-forgot-password .bs-txt-content {
  font-size: 14px;
}
.bs-forgot-password .bs-button{
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/entities/sessions/forgotPassword.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["\n.bs-forgot-password{\n  height: 100%;\n  min-height: 257px;\n}\n\n.bs-forgot-password > .bs-content {\n  width: 370px;\n  padding: 12px;\n}\n.bs-forgot-password .bs-txt-content {\n  font-size: 14px;\n}\n.bs-forgot-password .bs-button{\n  width: 100%;\n  margin-top: 8px;\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
