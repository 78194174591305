import React    from 'react';
import Application from '@uBehaviour/application';
import T        from "@cBehaviour/i18n";
import './category.css';
const categoriesWithImage = ['congratulation', 'deposit', 'dripping', 'flood', 'furniture', 'grafitti', 'lighting', 'playground', 'pollution', 'pothole', 'road', 'signs', 'tree', 'waste', 'water'];

const IssueCategory = ({ i18n, category : { key, label }}) => {
  const image = categoriesWithImage.includes(key) ? key : 'flood';
  return (
    <div className={ 'issue-item-title-category issue-item-title-category-' + key }>
      <img alt={ i18n.translateObject(label) } src={ `/images/categories/${ image }.png`} />
      <span><T>{ label }</T></span>
    </div>
  );
}

export default Application.Service.forward(['i18n'], IssueCategory);