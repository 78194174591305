// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-scrollBar {
  overflow-y: auto;
  overflow-x : hidden; 
  height: 100%;
  scroll-behavior: smooth;
}

.bs-scrollBar .bs-scrollbar-viewport {
  position: relative;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./../common/components/scrollBar/scrollBar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".bs-scrollBar {\n  overflow-y: auto;\n  overflow-x : hidden; \n  height: 100%;\n  scroll-behavior: smooth;\n}\n\n.bs-scrollBar .bs-scrollbar-viewport {\n  position: relative;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
