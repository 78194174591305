// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.bs-tag-action {
  padding:0px;
}

.bs-tag-action:hover {
  background-color: rgba(0, 0, 0, 0);
}

.bs-tag-action>.bs-content {
  background: rgba(255, 255, 255, 1)
}

.bs-tag-div {
  height: 25px;
  align-items: center;
  background: #fa7251;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 500;
  list-style: none;
  margin-right: 5px;
  padding: 5px;
}

.bs-tag-div-button {
  color: #666;
  cursor: pointer;
  display: inline-flex;
  padding-left: 5px;
}

.bs-tag-input {
  display:flex;
  box-sizing: border-box;
  height: 25px;
  padding: 0 10px;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./../common/components/input/tag.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,SAAS;EACT,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE;AACF;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,aAAa;AACf","sourcesContent":[".bs-tag {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  margin: 0;\n  padding: 0;\n  width: 100%;\n}\n\n.bs-tag-action {\n  padding:0px;\n}\n\n.bs-tag-action:hover {\n  background-color: rgba(0, 0, 0, 0);\n}\n\n.bs-tag-action>.bs-content {\n  background: rgba(255, 255, 255, 1)\n}\n\n.bs-tag-div {\n  height: 25px;\n  align-items: center;\n  background: #fa7251;\n  border-radius: 2px;\n  color: white;\n  display: flex;\n  font-weight: 500;\n  list-style: none;\n  margin-right: 5px;\n  padding: 5px;\n}\n\n.bs-tag-div-button {\n  color: #666;\n  cursor: pointer;\n  display: inline-flex;\n  padding-left: 5px;\n}\n\n.bs-tag-input {\n  display:flex;\n  box-sizing: border-box;\n  height: 25px;\n  padding: 0 10px;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
