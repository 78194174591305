// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-address {
  border: 1px solid #e3dfdc;
  background: #f2eeeb;
  color: #666;
  font-weight: 550;
  padding: 0px 10px;
  box-sizing: border-box;
  min-height: 27px;
  border-radius: 3px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/address.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".bs-old-input-address {\n  border: 1px solid #e3dfdc;\n  background: #f2eeeb;\n  color: #666;\n  font-weight: 550;\n  padding: 0px 10px;\n  box-sizing: border-box;\n  min-height: 27px;\n  border-radius: 3px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
