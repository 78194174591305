// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-release-list {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  color: #2c3e50;
}
.bs-release-list>.bs-release-list-title{
  background-color: #f5f5f5;
  padding: var(--std-padding);
  border-bottom: 1px solid #dddddd;
}
.bs-release-list>.bs-release-list-content {
  height: 100%;
  overflow: hidden;
}
.bs-release-list-content>*{
  padding: var(--std-padding)
}
.bs-release-list-footer {
  text-align: right;
  padding: var(--std-padding);
  border-top: 1px solid #dddddd;
  background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./../common/features/release/list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;EACvB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,gCAAgC;AAClC;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE;AACF;AACA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,6BAA6B;EAC7B,yBAAyB;AAC3B","sourcesContent":[".bs-release-list {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  justify-content: center;\n  color: #2c3e50;\n}\n.bs-release-list>.bs-release-list-title{\n  background-color: #f5f5f5;\n  padding: var(--std-padding);\n  border-bottom: 1px solid #dddddd;\n}\n.bs-release-list>.bs-release-list-content {\n  height: 100%;\n  overflow: hidden;\n}\n.bs-release-list-content>*{\n  padding: var(--std-padding)\n}\n.bs-release-list-footer {\n  text-align: right;\n  padding: var(--std-padding);\n  border-top: 1px solid #dddddd;\n  background-color: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
