// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues{
  position: relative;
  height: 100%;
}
.bs-issues-filter{
  height: 55px;
}
.bs-issues-list{
  height: calc(100% - 55px);
}`, "",{"version":3,"sources":["webpack://./src/applications/views/issues/list.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".bs-issues{\n  position: relative;\n  height: 100%;\n}\n.bs-issues-filter{\n  height: 55px;\n}\n.bs-issues-list{\n  height: calc(100% - 55px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
