import USession from "@uServices/session";

class Session extends USession{
  updateMine(oldUser, newUser){
    return super.updateMine(newUser).then(() => {
      if(oldUser.email !== newUser.email){
        this.application.getService("message").send("info", this.application.getService("i18n").translate("account_email_change_validation"));
      }
    });
  }
}

export default Session;