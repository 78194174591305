// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.issue-item {
    font-size: 12px;
    color: #5e676c;
    margin: 7.5px;
    border: solid 1px rgb(221, 217, 215);
    background-color: #fff;
}
.issue-item-content{
    padding: 10px;
}
.issue-item-title{
  overflow: hidden;
}
.issue-item-title-down{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.issue-item-title-tag{
    flex: 0 0 62%;
}
.issue-item-title-time{
    padding-top: 3px;
    font-size: 85%;
    flex: 0 0 38%;
}
.issue-item-title-city{
    padding-top: 5px;
    color: #aaa;
}
.issue-item-comment{
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
    border-top: 1px solid #eee;
    padding-top: 10px;
}
.issue-item-comment>i{
    font-size: 24px;
}
.issue-item-comment>div{
    padding-top: 7px;
    padding-left: 8px;
    text-align: justify;
    font-style: italic;
    word-break: normal;
    overflow-wrap: anywhere;
}`, "",{"version":3,"sources":["webpack://./src/entities/issue/item.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,cAAc;IACd,aAAa;IACb,oCAAoC;IACpC,sBAAsB;AAC1B;AACA;IACI,aAAa;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,0BAA0B;IAC1B,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,uBAAuB;AAC3B","sourcesContent":["\n.issue-item {\n    font-size: 12px;\n    color: #5e676c;\n    margin: 7.5px;\n    border: solid 1px rgb(221, 217, 215);\n    background-color: #fff;\n}\n.issue-item-content{\n    padding: 10px;\n}\n.issue-item-title{\n  overflow: hidden;\n}\n.issue-item-title-down{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n.issue-item-title-tag{\n    flex: 0 0 62%;\n}\n.issue-item-title-time{\n    padding-top: 3px;\n    font-size: 85%;\n    flex: 0 0 38%;\n}\n.issue-item-title-city{\n    padding-top: 5px;\n    color: #aaa;\n}\n.issue-item-comment{\n    display: flex;\n    margin-bottom: 15px;\n    margin-top: 15px;\n    border-top: 1px solid #eee;\n    padding-top: 10px;\n}\n.issue-item-comment>i{\n    font-size: 24px;\n}\n.issue-item-comment>div{\n    padding-top: 7px;\n    padding-left: 8px;\n    text-align: justify;\n    font-style: italic;\n    word-break: normal;\n    overflow-wrap: anywhere;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
