// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 .bs-old-input-text-like {
    padding: 0px 10px;
    box-sizing: border-box;
    position: relative;
    height: 25px;
    margin: 0;
    border: 1px solid #e3dfdc;
    background: #f2eeeb;
    color: #666;
    font-size: 12px;
    font-weight: 550;
    border-radius: 3px;
    width: 100%;
    outline: none;
}

.bs-old-input-text-like:focus {
    border-color:rgba(82,168,236,0.8);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 5px rgba(82,168,236,0.6);
}

.bs-old-input-text-like::placeholder{
    color: #aaa;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/input.css"],"names":[],"mappings":";CACC;IACG,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,iCAAiC;IACjC,2EAA2E;AAC/E;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["\n .bs-old-input-text-like {\n    padding: 0px 10px;\n    box-sizing: border-box;\n    position: relative;\n    height: 25px;\n    margin: 0;\n    border: 1px solid #e3dfdc;\n    background: #f2eeeb;\n    color: #666;\n    font-size: 12px;\n    font-weight: 550;\n    border-radius: 3px;\n    width: 100%;\n    outline: none;\n}\n\n.bs-old-input-text-like:focus {\n    border-color:rgba(82,168,236,0.8);\n    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 5px rgba(82,168,236,0.6);\n}\n\n.bs-old-input-text-like::placeholder{\n    color: #aaa;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
