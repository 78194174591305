// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-timeDuration {
  display: flex;
  align-items: center;
}
.bs-timeDuration-control{
  background-color: rgb(239, 239, 239);
  height: 24px;
  padding: 4px;
}
.bs-timeDuration-control:hover {
  cursor: pointer;
}
.bs-timeDuration .bs-input-numeric {
  text-align: center;
  width: 60px;
  -moz-appearance: textfield;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0px;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
  padding: 0px;
  height: 22px;
  font-size: 16px;
  width: 25px;
}
.bs-timeDuration .bs-input-numeric::-webkit-outer-spin-button,
.bs-timeDuration .bs-input-numeric::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

.bs-timeDuration-separator{
  width: 5px;
}

.bs-timeDuration-display {
  width: 25px;
  padding-top: 2px;
}

.bs-timeDuration-hour {
  text-align: right;
}
div.bs-timeDuration-hour {
  padding-right: 3px;
}

.bs-timeDuration-minute{
  text-align: left;
}
div.bs-timeDuration-minute {
  padding-left: 3px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/timeDuration.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,oCAAoC;EACpC,YAAY;EACZ,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,kCAAkC;EAClC,kBAAkB;EAClB,8BAA8B;EAC9B,4CAA4C;EAC5C,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,WAAW;AACb;AACA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-timeDuration {\n  display: flex;\n  align-items: center;\n}\n.bs-timeDuration-control{\n  background-color: rgb(239, 239, 239);\n  height: 24px;\n  padding: 4px;\n}\n.bs-timeDuration-control:hover {\n  cursor: pointer;\n}\n.bs-timeDuration .bs-input-numeric {\n  text-align: center;\n  width: 60px;\n  -moz-appearance: textfield;\n  background-color: rgba(0, 0, 0, 0);\n  border-radius: 0px;\n  border-color: rgba(0, 0, 0, 0);\n  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);\n  padding: 0px;\n  height: 22px;\n  font-size: 16px;\n  width: 25px;\n}\n.bs-timeDuration .bs-input-numeric::-webkit-outer-spin-button,\n.bs-timeDuration .bs-input-numeric::-webkit-inner-spin-button{\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.bs-timeDuration-separator{\n  width: 5px;\n}\n\n.bs-timeDuration-display {\n  width: 25px;\n  padding-top: 2px;\n}\n\n.bs-timeDuration-hour {\n  text-align: right;\n}\ndiv.bs-timeDuration-hour {\n  padding-right: 3px;\n}\n\n.bs-timeDuration-minute{\n  text-align: left;\n}\ndiv.bs-timeDuration-minute {\n  padding-left: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
