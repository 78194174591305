import React, { FunctionComponent } from 'react';
import { Subscribe } from '@cEntities/subscription';
import { NotificationSubscription, UserPro } from '@universal/types/business/User';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';
import T from '@universal/behaviour/i18n';
import MessageService from '@common/services/message';
import I18nService from '@universal/services/i18n';


interface StaterSubscriptionProps {
}

const StarterSubscription: FunctionComponent<StaterSubscriptionProps> = ({  }) => {
  const session = useService<SessionService>("session");
  const message = useService<MessageService>("message");
  const i18n    = useService<I18nService>('i18n');
  const user    = session.user as UserPro;

  const submit = React.useCallback((subscriptions: NotificationSubscription[]) => {
    session.updateSubscriptions(subscriptions)
      .then(() => {
        message.send("info", i18n.translate("subscription_updated"));
      });
  }, [session, message, i18n]);

  return (
    <Subscribe value={ user.personalSettings?.subscriptions || [] } submit={ submit }  withPush={ false }>
      <Subscribe.Group>
        <b><T>subscription_title</T></b>
        <Subscribe.Subscription name="onIssueCreated">
          <T>subscription_onIssueCreated</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onIssueStateUpdated">
          <T>subscription_onIssueStateUpdated</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onCitizenComment">
          <T>subscription_onCitizenComment</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onAdminComment">
          <T>subscription_onAdminComment</T>
        </Subscribe.Subscription>
      </Subscribe.Group>
    </Subscribe>
  );
};

export default StarterSubscription;