// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-quantity {
  display: flex;
  align-items: center;
}
.bs-quantity-control{
  background-color: rgb(239, 239, 239);
  height: 24px;
  padding: 4px;
}
.bs-quantity-control:hover {
  cursor: pointer;
}
.bs-quantity .bs-input-numeric {
  text-align: center;
  width: 60px;
  -moz-appearance: textfield;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0px;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
  padding: 0px;
  height: 22px;
  font-size: 16px;
  width: 55px;
}
.bs-quantity .bs-input-numeric::-webkit-outer-spin-button,
.bs-quantity .bs-input-numeric::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

.bs-quantity-display {
  width: 55px;
  text-align: center;
  padding-top: 2px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/quantity.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,oCAAoC;EACpC,YAAY;EACZ,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,kCAAkC;EAClC,kBAAkB;EAClB,8BAA8B;EAC9B,4CAA4C;EAC5C,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,WAAW;AACb;AACA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".bs-quantity {\n  display: flex;\n  align-items: center;\n}\n.bs-quantity-control{\n  background-color: rgb(239, 239, 239);\n  height: 24px;\n  padding: 4px;\n}\n.bs-quantity-control:hover {\n  cursor: pointer;\n}\n.bs-quantity .bs-input-numeric {\n  text-align: center;\n  width: 60px;\n  -moz-appearance: textfield;\n  background-color: rgba(0, 0, 0, 0);\n  border-radius: 0px;\n  border-color: rgba(0, 0, 0, 0);\n  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);\n  padding: 0px;\n  height: 22px;\n  font-size: 16px;\n  width: 55px;\n}\n.bs-quantity .bs-input-numeric::-webkit-outer-spin-button,\n.bs-quantity .bs-input-numeric::-webkit-inner-spin-button{\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.bs-quantity-display {\n  width: 55px;\n  text-align: center;\n  padding-top: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
