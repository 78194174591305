// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issue-comment {
  padding: 4px 12px 2px;
  border: 1px solid #ddd9d7;
  font: unset 1em/1.2 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif;
  overflow-wrap: break-word;
}

.issue-comment h2 {
  font-size: 22px;
  margin-bottom: 12px;
  margin-top: 0;
  line-height: 1.2;
}

.issue-comment h4 {
  font-size: 0.9em;
  color: #5e676c;
  margin: 10px 0;
  font-weight: 60
  0;
}
.issue-comment p {
  margin-bottom: 15px;
}

.issue-comment img {
  margin-bottom: 15px;
}

.issue-comment blockquote {
  border-color: #eeeeee;
  margin: 0 0 0.7em;
  color: #999999;
  padding: 0 0 0 15px;
}

.issue-comment .author-details {  
  font-size: 12px;
}

.issue-comment .author-details, .issue-comment h4 {
  color: #5e676c;
}

.issue-comment>.author-details>.span2 {
  text-align: center;
  width: 52.33px;
  position: absolute;
  font-size: 2em;
}

.issue-comment>.author-details>.span10 {
  padding-left: 45px;
  text-align:left;
  text-size-adjust:100%;
  min-height: 40px;
}

.issue-commentem {
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./../common/entities/issues/components/comment.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,yEAAyE;EACzE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd;GACC;AACH;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".issue-comment {\n  padding: 4px 12px 2px;\n  border: 1px solid #ddd9d7;\n  font: unset 1em/1.2 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif;\n  overflow-wrap: break-word;\n}\n\n.issue-comment h2 {\n  font-size: 22px;\n  margin-bottom: 12px;\n  margin-top: 0;\n  line-height: 1.2;\n}\n\n.issue-comment h4 {\n  font-size: 0.9em;\n  color: #5e676c;\n  margin: 10px 0;\n  font-weight: 60\n  0;\n}\n.issue-comment p {\n  margin-bottom: 15px;\n}\n\n.issue-comment img {\n  margin-bottom: 15px;\n}\n\n.issue-comment blockquote {\n  border-color: #eeeeee;\n  margin: 0 0 0.7em;\n  color: #999999;\n  padding: 0 0 0 15px;\n}\n\n.issue-comment .author-details {  \n  font-size: 12px;\n}\n\n.issue-comment .author-details, .issue-comment h4 {\n  color: #5e676c;\n}\n\n.issue-comment>.author-details>.span2 {\n  text-align: center;\n  width: 52.33px;\n  position: absolute;\n  font-size: 2em;\n}\n\n.issue-comment>.author-details>.span10 {\n  padding-left: 45px;\n  text-align:left;\n  text-size-adjust:100%;\n  min-height: 40px;\n}\n\n.issue-commentem {\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
