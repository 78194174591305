import React, { useEffect }                        from "react";
import { Route, Redirect, Switch, Link }  from "@cFeatures/router";
import Button                       from "@cComponents/button";
import Menu                         from "@cComponents/menu";
import About                        from "@static/about";
import Index                        from "@static/index";
import Citizen                      from "@static/citizen";
import ForgotPassword               from "./views/sessions/forgotPassword";
import Issue                        from "./views/issues";
import T                            from '@cBehaviour/i18n';
import Display                      from "@cComponents/displayIf";
import Login                        from "@entities/sessions/login";
import CreateAccount                from "@entities/sessions/createAccount";
import Conditions                   from "@entities/sessions/conditions";
import Unsubscribe                  from '@cFeatures/unsubscribe';
import LanguageSelector             from "./features/languageSelector";
import NavBar                       from "@entities/navBar";
import useService                   from "@uBehaviour/hooks/useService";
import I18nService                  from "@uServices/i18n";
import SessionService               from "@uServices/session";
import Modal                        from "@cComponents/modal";
import CIssue                       from "@common/entities/issues";


import UpdatePassword               from './views/sessions/updatePassword';
import Users                        from "./views/users";
import ConfigurationService         from "@universal/services/configuration";
import "./index.css";


const RootMenu = () => (
  <Menu.Section>
    <Menu.Item path="/">
    {() => (
       <img alt="BetterStreet" src={`/images/logos/betterstreet_color.svg`} style={{ margin: "5px", height: "60px" }} />
    )}
    </Menu.Item>
  </Menu.Section>
);

const MainMenu = () => (
  <Menu.Section>
    <Menu.Item path="/issues/" strict>
      <Button.Header.Standart icon="th" />
    </Menu.Item>
    <Menu.Item path="/map/" >
      <Button.Header.Standart icon="map-marker"/>
    </Menu.Item>
    <Menu.Item path="/issues/new" >
      <Button.Header icon="map-marker">
        <span className="bs-header-menu-button">
          <span className="bs-text">
            <T>NEW_ISSUE</T>
          </span>
          <span className="bs-icon fa fa-plus-circle" />
        </span>
      </Button.Header>
    </Menu.Item>
    <Menu.Item path="/about/" >
      <Button.Header>
        <span className="bs-header-menu-button">
          <span className="bs-text">
            <T>ABOUT</T>
          </span>
        </span>
      </Button.Header>
    </Menu.Item>
  </Menu.Section>
);

const Header = () => {
  const session = useService<SessionService>("session");
  return (
    <>
      <div className="bs-page-header">
        <Menu.Display />
        <div className="bs-page-header-constant">
          <LanguageSelector />
          <NavBar.Help />
          <Display.If condition={ session.isLogged() }>
            <Display.Then>
              <NavBar.User />
            </Display.Then>
            <Display.Else>
              <Link to="/login">
                <Button.Header>
                  <span className="bs-text">
                    <T>LOGIN</T>
                  </span>
                </Button.Header>
              </Link>
            </Display.Else>
          </Display.If>
        </div>
      </div>
      <RootMenu />
      <MainMenu />
    </>
  );
}


const OpenConditions = () => {
  const configurationService = useService<ConfigurationService>('configuration');
  
  const conditionUrl = configurationService.get('conditionUrl');
  
  useEffect(() => {        
    window.open(conditionUrl, "_blank");
  }, []); 
   return <Redirect to="/" fromRoot={ true } />;

};

const Routing = ({}) => {
  const i18n = useService<I18nService>('i18n');
  const session = useService<SessionService>('session');
  return (
    <Switch>        
      <Route path="/unsubscribe" component={ Unsubscribe }/>
      <Route path="/about" component={ About }/>
      <Route path="/citizen" component={ Citizen }/>
      <Route path="/allissues">
      {() => (
        <Redirect to="/issues" />
      )}
      </Route>
      <Route path="/issues/form">
      {() => (
        <Redirect to="/issues/new" />
      )}
      </Route>
      <Route path="/user/new">
      {() => (
        <Redirect to="/create_account" />
      )}
      </Route>
      <Route path="/conditions" component={ OpenConditions } />
      <Route path="/create_account" component={ CreateAccount } />
      <Route path="/forgot_password" component={ ForgotPassword } />
      <Route path="/issues/new">
      {() => (
        <Display.If condition={ session.isLogged() }>
          <Display.Then>
            <Issue.Form />
          </Display.Then>
          <Display.Else>
            <Login private />
          </Display.Else>
        </Display.If>
      )}
      </Route>
      <Route path="/issues/:id" component={ CIssue.Detail }/>
      <Route path="/issues" component={ Issue.List }/>
      <Route path="/map" component={ Issue.Map }/>
      <Route path="/login">
      {() => (
        <Display.If condition={ session.isLogged() }>
          <Display.Then>
            {() => (
              <Redirect to="/citizen" fromRoot/>
            )}
          </Display.Then>
          <Display.Else>
            {() => (
              <Login onLogin={() => (<Redirect to="/citizen" />) } />
            )}
          </Display.Else>
        </Display.If>
      )}
      </Route>
      <Route path="/users/me">
      {() => (
        <Display.If condition={ session.isLogged() }>
          <Display.Then>
            <Users.Me />
          </Display.Then>
          <Display.Else>
            {() => (
              <Redirect to="/citizen" />
            )}
          </Display.Else>
        </Display.If>
      )}
      </Route>
      <Route path="/" component={ Index }/>
    </Switch>
  );
}

const Content = () => {
  const session = useService<SessionService>("session");
  return (
    <div className="bs-page-content">
      <Routing />
      <Display.If condition={ session.mustResetPassword() }>
        <Modal.Show userCanClose={false} style={{ width: "40vw"}}>
          <UpdatePassword />
        </Modal.Show>
      </Display.If>
    </div>
  );
}

const View = () => (
  <React.Fragment>
    <Header />
    <Content />    
  </React.Fragment>
);

export default View;