// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
}
.social,
.social>div>a,
.social>div>a:visited{
    color: #aeaeae;
}
.social>div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.social>div>a{
    padding: 5px;
}
.social>div>a>i{
    font-size: 3em;
}
.social>p{
    font-size: 10px;
    text-decoration: underline;
}
.social>p:hover,
.social>div>a:hover{
    color: #959595;
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/entities/social.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;;;IAGI,cAAc;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,eAAe;IACf,0BAA0B;AAC9B;AACA;;IAEI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".social{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 12px;\n    margin-bottom: 12px;\n}\n.social,\n.social>div>a,\n.social>div>a:visited{\n    color: #aeaeae;\n}\n.social>div{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.social>div>a{\n    padding: 5px;\n}\n.social>div>a>i{\n    font-size: 3em;\n}\n.social>p{\n    font-size: 10px;\n    text-decoration: underline;\n}\n.social>p:hover,\n.social>div>a:hover{\n    color: #959595;\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
