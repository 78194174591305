export default {
  socketio_uri: { protocol: "https", host: `api.betterstreet.org`, path: "/ws" },
  api_uri: `https://api.betterstreet.org/2`,
  file_storage_read_url: "https://files.betterstreet.org",
  google_api_key:"AIzaSyAiaCx3IHKcLardtS6uueR_DD4wOPU4Z3I",
  default_map_position: {
    type:"Point",
    coordinates:[1.8069499, 51.1773266]
  },
  default_map_zoom: 6,
  facebook_app_id: '327309268293777',
  conditionUrl: "https://betterstreet.gitbook.io/conditions-betterstreet/",
  admin_url: "https://admin.betterstreet.org",
  env: "production",
  supportEmailDefault: "support@betterstreet.org",
  supportEmailBenelux: "support@inforius.be",
};

window.process = {
  env: 'production'
};