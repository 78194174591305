// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-container {
  color:  #5e676c;
  background-color: rgb(242, 238, 235);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bs-container>* {
  flex: 0 0 1;
  transform: translateY(-34px);
}
.bs-box {
  padding: 7px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}`, "",{"version":3,"sources":["webpack://./src/entities/sessions/session.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,4BAA4B;AAC9B;AACA;EACE,YAAY;EACZ,sBAAsB;EACtB,iCAAiC;EACjC,qCAAqC;AACvC","sourcesContent":[".bs-container {\n  color:  #5e676c;\n  background-color: rgb(242, 238, 235);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n.bs-container>* {\n  flex: 0 0 1;\n  transform: translateY(-34px);\n}\n.bs-box {\n  padding: 7px;\n  background-color: #fff;\n  border: 1px solid rgba(0,0,0,0.2);\n  box-shadow: 0 1px 3px rgba(0,0,0,0.1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
