import React        from 'react';
import T            from '@uBehaviour/i18n';
import Application  from "@uBehaviour/application";

import './unsubscribe.css';

export default Application.Service.forward(["session"], (props) => {
  return (
    <div className="bs-unsubscribe-container">
      <img alt="BetterStreet" src="/images/logos/betterstreet_color.svg" className="bs-unsubscribe-logo"></img>
      <h2><T>unsubscribe_succes</T></h2>
      <div><T>unsubscribe_explanation</T></div>
      {props.session.user.discriminator === "pro"
        ? <div><T>unsubscribe_explanation_pro</T></div>
        : <div><T>unsubscribe_explanation_citizen</T></div>
      }
      <div className="bs-unsubscribe-footer"><T>unsubscribe_footer</T></div>      
    </div>
  );
});