import React, { FunctionComponent } from 'react';
import Menu                     from "@cComponents/menu";
import Modal                    from "@cComponents/modal";
import { Router }               from "@cFeatures/router";
import Filter                   from "@cComponents/filter";
import MessageViewer                  from "@cComponents/message";
import Release                  from "@cEntities/release";
import Views                    from "./applications";

import SessionService           from '@uServices/session';
import I18nService              from '@uServices/i18n';
import ReleaseService           from '@uServices/release';
import useServiceListenener     from '@uBehaviour/hooks/useServiceListener';

import ReactDOM                 from 'react-dom';
import * as serviceWorker       from './serviceWorker';
import application              from './app.services';
import Application              from "@uBehaviour/application";
import ApplicationLib               from "@uLib/application";

import '@common/global.css';
import '@common/var.css';
import NavigatorService from '@common/services/navigator';

type AppContentProps = {
  application: ApplicationLib
}

const AppContent: FunctionComponent<AppContentProps> = ({ application }) => {

  const navigator = React.useMemo(() => application.getService<NavigatorService>('navigator').navigator, [application]);
  
  useServiceListenener<SessionService>('session', ['onServiceUpdated']);
  useServiceListenener<I18nService>('i18n', ['onServiceUpdated']);
  useServiceListenener<ReleaseService>('release', ['onServiceUpdated']);


  React.useEffect(() => {
    document.getElementsByClassName("bs-public-start")[0].remove();
  }, []);
  

  return (
    <Router navigator={ navigator }>
      <Filter.Aggregator>  
        <Modal.Manager>
          <Release.Next />
          <Menu.Context>
            <Views />
          </Menu.Context>
          <Release.AutoList application="citizen" />
        </Modal.Manager>
        <MessageViewer />
      </Filter.Aggregator>
    </Router>
  );
};

const AppContext = () => (
  <Application application={ application }>
    {() => (<AppContent application={application}  />)}
  </Application>
)

ReactDOM.render(
  (<AppContext />),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

