import React from 'react';

import Social from '@entities/social';
import JVS from './jvs';

import Slot         from "@cComponents/slot";
import T            from "@cBehaviour/i18n";
import Scrollbar    from "@cComponents/scrollBar";
import Button       from '@common/components/button';

import './about.css';

import booklet from '@assets/about/booklet.png'

// eslint-disable-next-line import/no-webpack-loader-syntax
import report from '!file-loader!@assets/about/report.svg';
import report_mobile from '@assets/about/report_mobile.gif';
import report_multiChannel from '@assets/about/report_multi-channel.png';

// eslint-disable-next-line import/no-webpack-loader-syntax
import centralization from '!file-loader!@assets/about/centralization.svg';

// eslint-disable-next-line import/no-webpack-loader-syntax
import centralization_onePlace from '!file-loader!@assets/about/centralization_one-place.svg';
import centralization_list from '@assets/about/centralization_list.gif';
import centralization_map from '@assets/about/centralization_map.png';

// eslint-disable-next-line import/no-webpack-loader-syntax
import planning from '!file-loader!@assets/about/planning.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import planning_calendar from '!file-loader!@assets/about/planning_calendar.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import planning_workorder from '!file-loader!@assets/about/planning_workorder.svg';
import planning_dashboard from '@assets/about/planning_dashboard.png';

// eslint-disable-next-line import/no-webpack-loader-syntax
import interaction from '!file-loader!@assets/about/interaction.svg';
import interaction_citizen from '@assets/about/interaction_citizen.gif';
import interaction_requestor from '@assets/about/interaction_requestor.png';
import interaction_population from '@assets/about/interaction_population.gif';

import gsenden from '@assets/about/gsenden.jpeg';
import cdister from '@assets/about/cdister.jpg';
import pgodechal from '@assets/about/pgodechal.jpg';

const images = {
  booklet,
  report,
  report_mobile,
  ['report_multi-channel']: report_multiChannel,
  centralization,
  ['centralization_one-place']: centralization_onePlace,
  centralization_list,
  centralization_map,
  planning,
  planning_calendar,
  planning_workorder,
  planning_dashboard,
  interaction,
  interaction_citizen,
  interaction_requestor,
  interaction_population,
  gsenden,
  cdister,
  pgodechal
};

class Block extends React.Component{
    static Title    = Slot();
    static Text     = Slot();
    render(){
        const title = Block.Title.get(this);
        const text  = Block.Text.get(this);
        return (
            <div className={ "block " + this._className }>
                <div className={ "block-header" }>
                    <img src={ this.props.img.src } alt={ this.props.img.alt } />
                </div>
                <div className={ "block-content" }>
                    <div>
                        <h3>{ title }</h3>
                        <p>{ text }</p>
                    </div>
                </div>
            </div>
        )
    }
}
class BlockImageSide extends Block{
    render(){
        this._className =  "block-image-side";
        if(this.props.right){
            this._className +=  " right";
        }
        return super.render();
    }
}
class BlockImageTop extends Block{
    render(){
        this._className =  "block-image-top";
        return super.render();
    }
}

export default class ScreenAbout extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        const abstracts = ['report', 'centralization', 'planning', 'interaction'];
        const details = [{
                type: 'report', 
                details:[
                    { type: 'mobile', right: false },
                    { type: 'multi-channel', right: true }
                ]
            }, {
                type: 'centralization', 
                details:[
                    { type: 'one-place', right: true },
                    { type: 'list', right: false },
                    { type: 'map', right: true }
                ]
            }, {
                type: 'planning', 
                details:[
                    { type: 'calendar', right: true },
                    { type: 'workorder', right: false },
                    { type: 'dashboard', right: true }
                ]
            }, {
                type: 'interaction', 
                details:[
                    { type: 'citizen', right: true },
                    { type: 'requestor', right: false },
                    { type: 'population', right: true }
                ]
            }];
        const testimonials = ['gsenden', 'cdister', 'pgodechal'];
        
        return (
            <Scrollbar>
                <div>
                    <div className={ "about-header" }>
                        <div  className={ "about-header-content-wrapper" }>
                            <div  className={ "about-header-content" }>
                                <div>
                                    <img src={ images.booklet } alt={ "Brochure" } />
                                    <Button.Text onClick={ () => window.open("https://www.jvs-mairistem.fr/environnement-territoire/gestion-travaux-interventions#p_2065", "_blank") }>
                                      <T>about_askbrochure</T>
                                    </Button.Text>
                                </div>
                                <h1>
                                    <T>about_title</T>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className={ "about-wrapper"}>
                        <div className={ "about-abstract" }>
                            {
                                abstracts.map(t => {
                                    return (
                                        <BlockImageTop img={{ src: images[t], alt: t}}>
                                            <BlockImageTop.Title><T>{'about_'+ t + '_title'}</T></BlockImageTop.Title>
                                            <BlockImageTop.Text><T>{'about_'+ t + '_content'}</T></BlockImageTop.Text>
                                        </BlockImageTop>
                                    )
                                })
                            }
                        </div>
                        <div className={ "about-details" }>
                            {
                                details.map(t => {
                                    return (
                                        <div className={ "about-detail" }>
                                            <h2><T>{'about_' + t.type + '_title'}</T></h2>
                                            {
                                                t.details.map(d => {
                                                    const current = t.type + '_' + d.type;
                                                    return (
                                                        <BlockImageSide img={{ src: images[current], alt: current}} right={ d.right }>
                                                            <BlockImageSide.Title><T>{'about_'+ current + '_title'}</T></BlockImageSide.Title>
                                                            <BlockImageSide.Text><T>{'about_'+ current + '_content'}</T></BlockImageSide.Text>
                                                        </BlockImageSide>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={ "about-testimonials" }>
                            <h2><T>{'about_testimony_title' }</T></h2>
                            <div>
                                {
                                    testimonials.map(t => {
                                        return (
                                            <div className={ "about-testimony" } >
                                                <div className={ "about-testimony-header" } >
                                                    <img src={ images[t] } alt={ t } />
                                                </div>
                                                <div className={ "about-testimony-content" } >
                                                    <h3><T>{'about_testimony_' + t + '_title'}</T></h3>
                                                    <p><T>{'about_testimony_' + t + '_content'}</T></p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <Social />
                        <JVS />
                    </div>
                </div>
            </Scrollbar>
        )
    }
}