import React        from 'react';
import moment       from 'moment';
import Cover        from './cover';
import Category     from './category';
import T            from "@cBehaviour/i18n";

import './item.css';
import 'font-awesome/css/font-awesome.min.css';

export default class IssueItem extends React.Component{
    render(){
        const issue     = this.props.data;
        const diffTime  = moment(issue.createdAt).fromNow();
        const address   = issue.location.address ? issue.location.address.zip + " " + issue.location.address.locality : <T>address_unknown</T>;
        const files     = issue.files.filter(file => file.mimeType && file.mimeType.startsWith("image"));
        return (
            <div className={ "issue-item" }  key={  this.props.data._id } >
                <Cover file={ files.length ? files[0] : null } state={ issue.state } fit="contain" width={ 250 }/>
                <div className={ "issue-item-content"}>
                    <div className={ 'issue-item-title'}>
                        <Category category={ issue.category } />
                        <div className='issue-item-title-down'>
                          <span className={ 'issue-item-title-time' }>
                              { diffTime }
                          </span>
                          <div className={ 'issue-item-title-city' }>{ address }</div>
                        </div>
                    </div>
                    <div className={ "issue-item-comment" }>
                        <i className={"fa fa-comment-o"}/>
                        <div>{this.props.children}</div>
                        <div>{ issue.description }</div>
                    </div>
                </div>
            </div>
        );
    }
}