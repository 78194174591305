import React        from "react";
import T            from '@cBehaviour/i18n'; 
import DropDown     from '@cComponents/dropDown';
import { Link, useLocation } from "@cFeatures/router";
import useService   from "@universal/behaviour/hooks/useService.ts";

const User = () => {
  const session = useService("session");
  const [, setLocation] = useLocation();

  const logout = React.useCallback(async() => {
    await session.logout();
    setLocation("/");
  }, [session, setLocation]);

  return (
    <DropDown.List alignRight={ true }>
      <DropDown.List.Main>
      <div>
        <span className="fa fa-user fa-lg" />
        <span>&nbsp;{ session.user.fullname }</span>
      </div>
      </DropDown.List.Main>
      <DropDown.List.List>
        <div className="bs-navBar-dropdown-options"><Link to="/users/me"><T>my_profile</T></Link></div>
        <div className="bs-navBar-dropdown-options" onClick={ logout }><T>logout</T></div>
      </DropDown.List.List>
    </DropDown.List>
  );
}

export default User;