import React from 'react';

import T    from '@cBehaviour/i18n';

import './jvs.css';

export default class JVS extends React.Component{
    render(){
        return (
            <div className={ "jvs" }>
                <p><T>jvs_betterstreet</T></p>
                <a href={ "http://www.jvs.fr" } target={ "_blank" }><img src="/images/logos/jvs_group.svg" alt="Logo JVS" /></a>
            </div>
        )
    }
}