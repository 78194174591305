import React        from "react";
import ReactDOM     from 'react-dom'
import { Link }     from "@cFeatures/router";
import _            from "lodash";
import Data         from "@uBehaviour/data";
import ScrollBar    from "@cComponents/scrollBar";
import Item         from "./item";
import T from "@universal/behaviour/i18n";
import Text, { Style } from "@common/components/text";

import "./list.css";

const ColumnContext = React.createContext();
class MultiColumn extends React.Component{
  constructor(props){
    super(props);
    this._container = React.createRef();
    this.update     = _.debounce(this.update.bind(this), 0);
    this._onResize  = _.debounce(this._onResize.bind(this), 0);
    this._toRemove  = [];
    this._columns   = [];
    this.createColumn();
  }
  add(child){
    return ReactDOM.createPortal(child, this.getColumn());
  }
  get columnWidth(){
    return 250;
  }
  createColumn(){
    if(this._columns.length === (window.document.body.clientWidth - window.document.body.clientWidth % this.columnWidth) / this.columnWidth){
      return false;
    }
    if(this._columns && this._columns.length){
      this._toRemove = this._columns;
    }
    this._columns = [];
    this._index = 0;
    for(let i = 0; i < window.document.body.clientWidth - window.document.body.clientWidth % this.columnWidth; i += this.columnWidth){
      const el = document.createElement("div");
      el.className = "bs-multicolumn-column";
      this._columns.push(el);
    }
    return true;
  }
  getColumn(){
    return this._columns[this._index++ % this._columns.length];
  }
  _onResize(){
    if(this.createColumn()){
      this.forceUpdate();
    }
  }
  componentDidMount(){
    window.addEventListener("resize", this._onResize);
    this.update();
  }
  componentDidUpdate(){
    this._toRemove.forEach(column => {
      column.parentElement.removeChild(column)
    });
    this._toRemove = [];
    this.update();
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this._onResize);
  }
  update(){
    this._columns.forEach(column => this._container.current.appendChild(column));
  }
  render(){
    return React.createElement(ColumnContext.Provider, { value: this }, 
      React.createElement("div", { className: "bs-multicolumn", ref: this._container }, this.props.children())
    );
  }
}
const ColumnItem = (props) => {
  return React.createElement(ColumnContext.Consumer, { }, multiColumn => {
    return multiColumn.add(props.children);
  });
}
export default class IssueList extends React.Component{
  constructor(props){
    super(props);
    this._scroll  = React.createRef();
    this._list    = React.createRef();
  }
  componentDidMount(){
    this._scroll.current.onScroll.addListener(this);
    this._loadPage();
  }
  componentWillUnmount(){
    this._scroll.current.onScroll.removeListener(this);
  }
  handleEvent(ev, component){
    if(ev === 'scroll'){
      this._loadPage();
    }
  }
  componentDidUpdate(){
    this._loadPage();
  }
  _loadPage(){
    return this._tryLoad().then(result => {
      if(result && !result.alreadyLoad){
        return this._loadPage();
      }
      return false;
    });
  }
  _tryLoad(){
    const rest = (1 - this._scroll.current.position) * this._scroll.current.viewPortHeight;
    if(!this._list.loaded && rest / 4 < this._scroll.current.height){
      return this._list.current.loadNext();
    }
    return Promise.resolve(false);
  }
  render(){
    return (
      <ScrollBar ref={ this._scroll }>
        <MultiColumn>
        {() => (
          <Data.List persist={ true } ref={ this._list } query={ this.props.query } model={ "Issue" } load={{ category: true, files: true }} sort={{ createdAt: -1}} pageSize={ 60 }>
            <Data.List.Item>
            {(issue, props) => (
              <ColumnItem>
                <Link to={ `/${ issue._id }` }>
                  <Item key={ issue._id } data={issue} >{ props.index }</Item>
                </Link>
              </ColumnItem>
            )}
            </Data.List.Item>
            <Data.List.IfEmpty>
              <div className="bs-multicolumn-empty">
                <span className="fa fa-5x fa-info-circle" />
                <Text style={ Style.huge.center.bold }>
                  <T>issues_list_empty</T>
                </Text>
                <Text style={ Style.large.center }>
                  <T>issues_list_empty_instructions</T>
                </Text>
              </div>
            </Data.List.IfEmpty>
          </Data.List>
        )}
        </MultiColumn>
      </ScrollBar>
    )
  }
}