// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-checkbox>input:first-child {
    display: none;
}

.bs-old-input-checkbox input {
    width: auto;
    height: auto;
}

.bs-old-input-checkbox .bs-old-input-checkbox-inline {
    display: flex;
}

.bs-old-input-checkbox label {
    padding: 4px 10px;
    /* border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey; */
    font-size: 0.9em;
}
.bs-old-input-checkbox label:hover{
    cursor: pointer;
}
.bs-old-input-checkbox label:first-child{
    border-top-left-radius: var(--std-radius);
    border-bottom-left-radius: var(--std-radius);
}
.bs-old-input-checkbox label:last-child{
    /* border-right: 1px solid grey; */
    border-top-right-radius: var(--std-radius);
    border-bottom-right-radius: var(--std-radius);
}

.bs-old-input-checkbox input{
    display: none;
}

.bs-old-input-checkbox .bs-old-input-checkbox-value {
    color: #b3b3b3
}

.bs-old-input-checkbox .bs-old-input-checkbox-value-checked{
    background-color: #fa7251;
    opacity: 0.9;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./../common/components/old/input/checkbox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB;;oCAEgC;IAChC,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IACI,yCAAyC;IACzC,4CAA4C;AAChD;AACA;IACI,kCAAkC;IAClC,0CAA0C;IAC1C,6CAA6C;AACjD;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;AACJ;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".bs-old-input-checkbox>input:first-child {\n    display: none;\n}\n\n.bs-old-input-checkbox input {\n    width: auto;\n    height: auto;\n}\n\n.bs-old-input-checkbox .bs-old-input-checkbox-inline {\n    display: flex;\n}\n\n.bs-old-input-checkbox label {\n    padding: 4px 10px;\n    /* border-top: 1px solid grey;\n    border-left: 1px solid grey;\n    border-bottom: 1px solid grey; */\n    font-size: 0.9em;\n}\n.bs-old-input-checkbox label:hover{\n    cursor: pointer;\n}\n.bs-old-input-checkbox label:first-child{\n    border-top-left-radius: var(--std-radius);\n    border-bottom-left-radius: var(--std-radius);\n}\n.bs-old-input-checkbox label:last-child{\n    /* border-right: 1px solid grey; */\n    border-top-right-radius: var(--std-radius);\n    border-bottom-right-radius: var(--std-radius);\n}\n\n.bs-old-input-checkbox input{\n    display: none;\n}\n\n.bs-old-input-checkbox .bs-old-input-checkbox-value {\n    color: #b3b3b3\n}\n\n.bs-old-input-checkbox .bs-old-input-checkbox-value-checked{\n    background-color: #fa7251;\n    opacity: 0.9;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
