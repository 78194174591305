// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-map {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bs-issues-map > .bs-wrapper {
  flex: 1 1 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/applications/views/issues/map.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,WAAW;EACX,WAAW;AACb","sourcesContent":[".bs-issues-map {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n.bs-issues-map > .bs-wrapper {\n  flex: 1 1 0;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
