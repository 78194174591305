// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-renew-password-container {
  margin: 10px;
}

.bs-renew-password-container div {
  margin-bottom: 10px;
}

.bs-renew-password-container button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./../common/entities/user/renewPassword.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".bs-renew-password-container {\n  margin: 10px;\n}\n\n.bs-renew-password-container div {\n  margin-bottom: 10px;\n}\n\n.bs-renew-password-container button {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
