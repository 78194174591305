// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-filter-collapsible{
  margin-bottom: 24px;;
}

.bs-filter-collapsible-title{
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 12px;
  align-items: center;
}

.bs-filter-collapsible-title-text{
  flex-grow: 2;
  font-size: 16px;
}

.bs-filter-collapsible-title > .fa{
  color: #aaaaaa;
}
.bs-filter-collapsible-openable > .bs-filter-collapsible-title {
  cursor: pointer;
}
.bs-filter-collapsible-alwaysOpen > .bs-filter-collapsible-title > .fa-caret-down,
.bs-filter-collapsible-alwaysOpen > .bs-filter-collapsible-title > .fa-caret-up{
  display: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/collapsable.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,gCAAgC;EAChC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;;EAEE,aAAa;AACf","sourcesContent":["\n.bs-filter-collapsible{\n  margin-bottom: 24px;;\n}\n\n.bs-filter-collapsible-title{\n  display: flex;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #aaaaaa;\n  margin-bottom: 12px;\n  align-items: center;\n}\n\n.bs-filter-collapsible-title-text{\n  flex-grow: 2;\n  font-size: 16px;\n}\n\n.bs-filter-collapsible-title > .fa{\n  color: #aaaaaa;\n}\n.bs-filter-collapsible-openable > .bs-filter-collapsible-title {\n  cursor: pointer;\n}\n.bs-filter-collapsible-alwaysOpen > .bs-filter-collapsible-title > .fa-caret-down,\n.bs-filter-collapsible-alwaysOpen > .bs-filter-collapsible-title > .fa-caret-up{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
