// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-input-file-gallery-inputFile-input{
  display: none;
}
.bs-input-file-gallery-inputFile:hover {
  cursor: pointer;
}
.bs-input-file-gallery-inputFile>.bs-file-frame {
  margin: 2px;
}

.bs-input-file-gallery-inputFile-deletor{
  position: relative;
}
.bs-input-file-gallery-inputFile-deletor-button{
  position: absolute;
  left: 2px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  font-size: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 8px 0px black;
  transform: translate(-50%, -50%);
}
.bs-input-file-gallery-inputFile-deletor-button>.fa {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/file/gallery.css"],"names":[],"mappings":";AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,0CAA0C;EAC1C,iCAAiC;EACjC,gCAAgC;AAClC;AACA;EACE,YAAY;AACd","sourcesContent":["\n.bs-input-file-gallery-inputFile-input{\n  display: none;\n}\n.bs-input-file-gallery-inputFile:hover {\n  cursor: pointer;\n}\n.bs-input-file-gallery-inputFile>.bs-file-frame {\n  margin: 2px;\n}\n\n.bs-input-file-gallery-inputFile-deletor{\n  position: relative;\n}\n.bs-input-file-gallery-inputFile-deletor-button{\n  position: absolute;\n  left: 2px;\n  top: 2px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 14px;\n  height: 14px;\n  font-size: 12px;\n  border-radius: 50%;\n  background-color: rgba(255, 255, 255, 0.9);\n  box-shadow: 0px 1px 8px 0px black;\n  transform: translate(-50%, -50%);\n}\n.bs-input-file-gallery-inputFile-deletor-button>.fa {\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
