// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.bs-url{
  color: rgba(0, 0, 255, 1) !important;
}
a.bs-url:visited{
  color: rgba(125, 0, 255, 1) !important;
}
a.bs-url>span{
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./../common/components/url.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;AACA;EACE,sCAAsC;AACxC;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["a.bs-url{\n  color: rgba(0, 0, 255, 1) !important;\n}\na.bs-url:visited{\n  color: rgba(125, 0, 255, 1) !important;\n}\na.bs-url>span{\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
