import React    from "react";
import T        from "@cBehaviour/i18n";
import Display  from "@cComponents/displayIf";

import "./facebook.css";

const url       = "https://connect.facebook.net/#language#/sdk.js";
export default class Facebook extends React.Component{
  static Context        = React.createContext(null);
  static scriptElement  = null;
  static FB             = null;
  constructor(props){
    super(props);
    this.state = { FB: null, language: null };
  }
  init(){
    if(this.props.appId){
      if(!Facebook.FB || Facebook.scriptElement.src !== url.replace("#language#", this.props.language)){
        if(Facebook.scriptElement){
          document.head.removeChild(Facebook.scriptElement);
        }
        Facebook.FB = new Promise((resolve, reject) => {
          Facebook.scriptElement        = document.createElement("script");
          Facebook.scriptElement.async  = true;
          Facebook.scriptElement.defer  = true;
          Facebook.scriptElement.src    = url.replace("#language#", this.props.language);
          Facebook.scriptElement.onload = () => {
            window.FB.init({
              appId            : this.props.appId,
              status           : true,
              autoLogAppEvents : true,
              xfbml            : false,
              version          : 'v7.0'
            });
            resolve(window.FB);
          }
          document.head.appendChild(Facebook.scriptElement);
        });
      }
      Facebook.FB.then(FB => {
        FB.getLoginStatus((response) => {
          this.setState({ FB: window.FB, language: this.props.language });
        });
      });
    }
  }
  _login(){
    this.state.FB.login(response => {
      if(response.status === 'connected'){
        this.props.submit({
          type: "facebook",
          accessToken: response.authResponse.accessToken
        });
      }
    }, {scope: 'public_profile,email'});
  }
  render(){
    if(!this.state.FB || this.state.language !== this.props.language){
      this.init();
    }
    return (
      <Facebook.Context.Provider value={{ login: () => this._login(), user: this.state.user }}>
        { this.props.children }
      </Facebook.Context.Provider>
    )
  }
};

Facebook.Login = class Login extends React.Component{
  render(){
    return (
      <Facebook.Context.Consumer>
        {context => {
          if(!context) return null;
          return (
            <div className="bs-fb" onClick={ () => context.login() }>
              <Display.If condtion={ context.user }>
                <Display.Then>
                  <img />
                  <span><T>continue_as</T></span><span></span>
                </Display.Then>
                <Display.Else>
                  <span className="fa fa-facebook-official" /><span><T>connect_with_facebook</T></span>
                </Display.Else>
              </Display.If>
            </div>
          )
        }}
      </Facebook.Context.Consumer>
    )
  }
}
