// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-old-radio>div {
    display: flex;
}

.bs-old-input-old-radio>div>label:first-child>button {
  border-bottom-left-radius : var(--std-radius);
  border-top-left-radius : var(--std-radius);
}

.bs-old-input-old-radio>div> *:not(:last-child) {
  /* border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-bottom: 1px solid grey; */
}

.bs-old-input-old-radio>div>label:last-child>button {
  /* border: 1px solid grey; */
  border-bottom-right-radius : var(--std-radius);
  border-top-right-radius : var(--std-radius);
}

.bs-old-input-old-radio input{
    display: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/radio.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;EACE,6CAA6C;EAC7C,0CAA0C;AAC5C;;AAEA;EACE;;kCAEgC;AAClC;;AAEA;EACE,4BAA4B;EAC5B,8CAA8C;EAC9C,2CAA2C;AAC7C;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".bs-old-input-old-radio>div {\n    display: flex;\n}\n\n.bs-old-input-old-radio>div>label:first-child>button {\n  border-bottom-left-radius : var(--std-radius);\n  border-top-left-radius : var(--std-radius);\n}\n\n.bs-old-input-old-radio>div> *:not(:last-child) {\n  /* border-top: 1px solid grey;\n  border-left: 1px solid grey;\n  border-bottom: 1px solid grey; */\n}\n\n.bs-old-input-old-radio>div>label:last-child>button {\n  /* border: 1px solid grey; */\n  border-bottom-right-radius : var(--std-radius);\n  border-top-right-radius : var(--std-radius);\n}\n\n.bs-old-input-old-radio input{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
