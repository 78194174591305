// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 .bs-input-textBase {
  padding: 0px 10px;
  box-sizing: border-box;
  position: relative;
  height: 25px;
  margin: 0;
  border: 1px solid #e3dfdc;
  background: #f2eeeb;
  color: #666;
  font-size: 12px;
  font-weight: 550;
  border-radius: 3px;
  width: 100%;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./../common/components/input/textBase.css"],"names":[],"mappings":";CACC;EACC,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf","sourcesContent":["\n .bs-input-textBase {\n  padding: 0px 10px;\n  box-sizing: border-box;\n  position: relative;\n  height: 25px;\n  margin: 0;\n  border: 1px solid #e3dfdc;\n  background: #f2eeeb;\n  color: #666;\n  font-size: 12px;\n  font-weight: 550;\n  border-radius: 3px;\n  width: 100%;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
