import { Service } from "@uLib/application";

export default class CurrentTenantFallbackService extends Service {
  constructor() {
    super("currentTenant");
  }
  isSelected(){
    return false;
  }
  get current(){
    return null;
  }
  get currentId(){
    return null;
  }
}