// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-unsubscribe-logo {
  width: "300px";
  height: "300px";
}

.bs-unsubscribe-container {
  display: flex;
  padding-top: 50px;
  width:40%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
}
.bs-unsubscribe-footer {
  margin-top: 30px;
  font-size:14px;
}`, "",{"version":3,"sources":["webpack://./../common/features/unsubscribe.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,SAAS;EACT,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".bs-unsubscribe-logo {\n  width: \"300px\";\n  height: \"300px\";\n}\n\n.bs-unsubscribe-container {\n  display: flex;\n  padding-top: 50px;\n  width:40%;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  flex-direction: column;\n  align-items: center;\n  line-height: 1.5;\n}\n.bs-unsubscribe-footer {\n  margin-top: 30px;\n  font-size:14px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
