import React          from "react";
import T              from "@cBehaviour/i18n";
import Form           from "@cBehaviour/form";
import Input          from "@cComponents/old/input";
import Button         from "@cComponents/button";
import Field          from "@cComponents/old/field";
import FormError      from "@cComponents/formError";
import Application    from "@uBehaviour/application";

import "./session.css";
import "./createAccount.css";
import { register } from "@root/serviceWorker";

class CreateAccount extends React.Component{
  constructor(props){
    super(props);
    this._form      = React.createRef();
    this._validate  = this._validate.bind(this);
    this._submit    = this._submit.bind(this);
    this._presubmit = this._presubmit.bind(this);
  }
  _validate(value, errors){
    if(!value.condition){
      errors.properties.push({ path: "condition", error: "accept_condition" });
    }
    if(value.password !== value.password_verification){
      errors.properties.push({ path: "password_verification", error: "not_corresponding_password" });
    }
    return Promise.resolve(errors);
  }
  _presubmit(value){
    delete value.condition;
    delete value.password_verification;
    return value;
  }
  _submit(value){
    return this.props.api.service("users", "post").execute(value).then(() => {
        this.props.microstore.set("login_info", "new_user_account_validate_mail");
        this.props.navigator.push("/login");
      });
  }
  render(){
    const ret = (
      <div className="bs-container bs-create-account">
        <div className="bs-content bs-box">
          <div className="bs-box-title">
            <h2><T>new_user_account</T></h2>
          </div>
          <div className="bs-form-createuser--content">
            <div className="bs-txt-content">
            <Form.Simple ref={ this._form } name="create_account" submit={ this._submit } validate={ this._validate } presubmit={ this._presubmit }>
              <FormError>
                <Field>
                  <Field.Label className="bs-txt-content"><T>email</T></Field.Label>
                  <Field.Input><Input.Email name="email" required /></Field.Input>
                </Field>
                <Field>
                  <Field.Label className="bs-txt-content"><T>firstname</T></Field.Label>
                  <Field.Input><Input.Text name="firstname" required /></Field.Input>
                </Field>
                <Field>
                  <Field.Label className="bs-txt-content"><T>lastname</T></Field.Label>
                  <Field.Input><Input.Text name="lastname" required /></Field.Input>
                </Field>
                <Field>
                  <Field.Label className="bs-txt-content"><T>password</T></Field.Label>
                  <Field.Input><Input.Password name="password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}" required /></Field.Input>
                </Field>
                <Field>
                  <Field.Label className="bs-txt-content"><T>password_verification</T></Field.Label>
                  <Field.Input><Input.Password name="password_verification" /></Field.Input>
                </Field>
                <label className="bs-txt-content">
                <Input.Boolean className="bs-condition" name="condition">
                    <T>condition_begin</T>
                    <span className="bs-link" onClick={ (ev) => { ev.preventDefault(); window.open(this.props.configuration.get("conditionUrl"))}}>
                      <T>condition_link</T>
                    </span>
                    <T>condition_end</T>
                  </Input.Boolean>
                </label>
              </FormError>
            </Form.Simple>
              <Button.Text onClick={ () => { this._form.current.submit() }}>
                <T>create_account</T>
              </Button.Text>
            </div>
          </div>
        </div>
      </div>
    );
    return ret;
  }
}

export default Application.forward(["api", "microstore", "configuration", "navigator"], [], CreateAccount);

