import React        from "react";
import _            from "lodash"

import Issue        from "@entities/issue";
import Data         from "@uBehaviour/data";
import Map          from '@cComponents/map/index';
import Geometry     from "@cLib/geometry";
import Url          from "@cLib/url";
import Application  from "@uBehaviour/application";

import Filter       from "@cComponents/filter";

import "./map.css";

class IssuesMap extends React.Component{
  constructor(props){
    super(props);
    this.state          = { issue: null };
    this.onMapChanged   = _.debounce(this.onMapChanged.bind(this), 1500);
    this._defaultCenter = Geometry.buildFromGeoJSON({ type:"Point", coordinates:[6.3,47]});
    this._zoom          = 5.9;
    this._new           = true;

    const filters = this.getGeoFilter(Geometry.buildFromGeoJSON({ type:"Point", coordinates:[-6.88, 19.48]}), Geometry.buildFromGeoJSON({ type:"Point", coordinates:[41.98, 51.58]}));
    
    if(this.props.microstore.has("map")){
      const microstore    = this.props.microstore.get("map");
      this._defaultCenter = microstore.center ? microstore.center : this._defaultCenter;
      this._zoom          = microstore.zoom ? microstore.zoom : this._zoom;
    }
  }
  onMapChanged(set, southWest, northEast, center, zoom){
    if(this._new){
      this._new = false;
      return;
    }
    this._defaultCenter = center;
    this._zoom = zoom;
    this.props.microstore.set("map", {
      center: center,
      zoom: zoom
    });
    set(this.getGeoFilter(southWest, northEast));
  }
  getGeoFilter(southWest, northEast){
    const min0 = Math.min(southWest.toGeoJSON().coordinates[0], northEast.toGeoJSON().coordinates[0]);
    const max0 = Math.max(southWest.toGeoJSON().coordinates[0], northEast.toGeoJSON().coordinates[0]);
    const min1 = Math.min(southWest.toGeoJSON().coordinates[1], northEast.toGeoJSON().coordinates[1]);
    const max1 = Math.max(southWest.toGeoJSON().coordinates[1], northEast.toGeoJSON().coordinates[1]);
    return [{ $gte: min0, $lte: max0 }, { $gte: min1, $lte: max1 }];
  }
  onShapeClicked(shape){
    this.props.navigator.push("/issues/" + shape.data._id);
  }
  componentWillUnmount(){
    if(this._clear){
      this._clear();
    }
  }
  render(){
    return (
      <div className="bs-issues-map">
        <div className="bs-issues-filter"><Issue.Filter noMerged/></div>
        <div className="bs-wrapper">
          <Filter.Generic name="location" buildQuery={ value =>  ({ "location.position.coordinates.0": value[0], "location.position.coordinates.1": value[1] })}>
          {(location, set, clear) => {
            this._clear = clear;
            return (
              <Filter.Subject>
              {composeQuery => (
                <Data.Query model={"Issue"} query={ composeQuery({ "location.position": { $exists: true }, state: { $ne: "merged" }}) } pageSize={ 200 } load={{ category: true }} sort={{ createdAt: -1}}>
                {(issues) => (
                  <Map type={ Map.Dynamic } onDragEnd={(southWest, northEast, center, zoom) => this.onMapChanged(set, southWest, northEast, center, zoom)} onZoomChanged={(southWest, northEast, center, zoom) => this.onMapChanged(set, southWest, northEast, center, zoom)} defaultcenter={ this._defaultCenter } zoom={this._zoom}>
                    { issues.map(issue => (
                      <Map.Shape
                        key={ issue._id }
                        readOnly={ true }
                        geometry={issue.location.position}
                        data={ issue }
                        icon={ Url.fromRoot(`/images/maps/${issue.category.key || "generic"}_${issue.state}.png`)}
                        onClick={ this.onShapeClicked.bind(this) }
                      />
                    )) }
                  </Map>
                )}
                </Data.Query>
              )}
              </Filter.Subject>
            )
          }}
          </Filter.Generic>
        </div>
      </div>
    );
  }
}

export default Application.Service.forward(["microstore", "geolocation", "navigator"], IssuesMap);