import { Service } from "@uLib/application";

export default class MicroStoreService extends Service {
  constructor() {
    super("microstore");
    this._store = {};
  }
  has(name){
    return !!this._store[name];
  }
  get(name){
    if(!this.has(name)){
      throw new Error(`Unknow microstore: "${name}"`);
    }
    return this._store[name];
  }
  set(name, data){
    this._store[name] = data;
  }
  remove(name){
    if(!this.has(name)){
      throw new Error(`Unknow microstore: "${name}"`);
    }
    delete this._store[name];
  }
}
