import React from 'react';
import PropTypes from 'prop-types';

const imageLinks = {
  ios: (language, proportion) => 'url(https://linkmaker.itunes.apple.com/images/badges/#i18n#/badge_appstore-lrg.svg'.replace("#i18n#", language.iso.toLowerCase()) + ") 0% 0% / 100%",
  android: (language, proportion) => 'url(https://play.google.com/intl/en_us/badges/images/generic/#i18n#_badge_web_generic.png'.replace("#i18n#", language.bs.toLowerCase()) + `) -${14 * proportion}px -${14 * proportion}px / 114%`,
};

class MobileStoreButton extends React.Component {
  static propTypes = {
    store: PropTypes.oneOf(['ios', 'android']).isRequired,
    url: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    linkStyles: PropTypes.object,
    linkProps: PropTypes.object,
  };
  static defaultProps = {
    proportion: 1
  }
  render() {
    const size = {
      height: 60,
      width: 201,
    };
    const {
      store,
      url,
      linkStyles,
      linkProps,
      language,
      proportion,
      ...props
    } = this.props;
    const height = size.height * proportion;
    const width = size.width * proportion;
    const defaultLinkStyles = {
      background: `${imageLinks[store](language, proportion)} no-repeat`,
      display: 'inline-block',
      overflow: 'hidden',
      textDecoration: 'none',
      height: '100%',
      width: '100%',
      ...linkStyles,
    };

    return (
      <div style={{ height, width, display: 'inline-block' }} {...props}>
        <a
          style={defaultLinkStyles}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          {...linkProps}
        >
          &nbsp;
        </a>
      </div>
    );
  }
}

export default MobileStoreButton;
