// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jvs{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
}
.jvs>p{
    font-size: 11px;
    color: #aeaeae;
}
.jvs>a>img{
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/static/jvs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,YAAY;AAChB","sourcesContent":[".jvs{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-bottom: 24px;\n}\n.jvs>p{\n    font-size: 11px;\n    color: #aeaeae;\n}\n.jvs>a>img{\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
