import React from 'react';
import T    from '@cBehaviour/i18n';
import useService from "@uBehaviour/hooks/useService";

import ConfigurationService from '@uServices/configuration';
import './social.css';

const Social: React.FunctionComponent = () => {
  const config = useService<ConfigurationService>("configuration");

  return (
    <div className={ "social" }>
        <div>
            <a href={ "https://www.facebook.com/BetterStreet/" } target={ "_blank" } rel="noreferrer"><i className={ "fa fa-facebook"} /></a>
            <a href={ "https://twitter.com/betterstreet" } target={ "_blank" }rel="noreferrer"><i className={ "fa fa-twitter"} /></a>
            <a href={ "mailto:info@betterstreet.org" }><i className={ "fa fa-envelope"} /></a>
        </div>
        <p><a href={config.get("conditionUrl")} target={ "_blank" } rel="noreferrer"><T>betterstreet_condition_link</T></a></p>
    </div>
  )
};

export default Social;