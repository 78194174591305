
import React        from "react";
import T            from "@cBehaviour/i18n";
import Form         from "@cBehaviour/form";
import Field        from "@cComponents/old/field";
import Input        from "@cComponents/old/input";
import Application  from '@uBehaviour/application';
import FormError    from "@cComponents/formError";

import "./form.css";

export default Application.Service.forward(["session"], React.forwardRef((props, ref) => (
  <div className="bs-form-user">
    <Form.Simple ref={ref} name="me" value={ props.datas } submit={ (value) => props.session.updateMine(props.datas, value) }>
    {(value, errors) => (
      <FormError errors={errors}>
        <Field>
          <Field.Label><T>email</T></Field.Label>
          <Field.Input><Input.Text name="email"/></Field.Input>
        </Field>
        <div className="bs-form-user-identify">
          <Field>
            <Field.Label><T>firstname</T></Field.Label>
            <Field.Input><Input.Text name="firstname"/></Field.Input>
          </Field>
          <Field>
            <Field.Label><T>lastname</T></Field.Label>
            <Field.Input><Input.Text name="lastname"/></Field.Input>
          </Field>
        </div>
      </FormError>
    )}
    </Form.Simple>
  </div>
)));