// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tooltip-subject{
}
`, "",{"version":3,"sources":["webpack://./../common/components/tooltip.css"],"names":[],"mappings":"AAAA;AACA","sourcesContent":[".bs-tooltip-subject{\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
