// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-multicolumn{
  display: flex;
  flex-direction: row;
}
.bs-multicolumn-column{
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
}

.bs-multicolumn-empty{
  width: 100%;
  margin-top: 70px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/entities/issue/list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".bs-multicolumn{\n  display: flex;\n  flex-direction: row;\n}\n.bs-multicolumn-column{\n  overflow: hidden;\n  flex-shrink: 1;\n  flex-grow: 1;\n  flex-basis: 0;\n}\n\n.bs-multicolumn-empty{\n  width: 100%;\n  margin-top: 70px;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
