import React       from "react";
import T           from '@cBehaviour/i18n';
import cButton     from '@cComponents/button';
import Nudge       from "@cComponents/nudge";
import Title       from "@cComponents/title";
import Application from "@uBehaviour/application"

import './renewPassword.css'

const Button = cButton.withStyle(cButton.Stylized.orange.round);

export default Application.Service.forward(["session"], props => (
  <div className="bs-renew-password-container">
    <Title><T>renew_password</T></Title>
    <Nudge type="info"><T>renew_password_info</T></Nudge>
    <Button onClick={() => {
      props.session.renewPassword();
      if (props.onClose) {
        props.onClose();
      } 
    }}><T>send</T></Button>
  </div>
));