// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-filter{
  background-color: #f9f7f5;
  border-bottom: 1px solid #ded4cc;
}
.issues-filters{
  display: flex;
  padding: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  gap: var(--std-large-size-inter-element)
}
.issues-filters .bs-input-text-like{
  height: 27px;
}
.issues-filters>*{
  min-width: 180px;
}
.issues-filters-name{
  display: flex;
}
.issues-filters-name .bs-button-icon{
  height: 27px;
  width: 27px;
  font-size: 27px;
  background-color: #e6ded5;
}
.issues-filters-name .bs-button-icon,
.issues-filters-name .bs-button-icon>div{
  border-radius: 3px 0px 0px 3px;
}
.issues-filters-name .bs-input-text{
  border-radius: 0px 3px 3px 0px;
  border-width: 1px 1px 1px 0px;
}
.issues-filters-name .bs-input-text,
.issues-filters .bs-select-display-default{
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/entities/issue/filter.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB;AACF;AACA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,yBAAyB;AAC3B;AACA;;EAEE,8BAA8B;AAChC;AACA;EACE,8BAA8B;EAC9B,6BAA6B;AAC/B;AACA;;EAEE,uBAAuB;AACzB","sourcesContent":[".bs-issues-filter{\n  background-color: #f9f7f5;\n  border-bottom: 1px solid #ded4cc;\n}\n.issues-filters{\n  display: flex;\n  padding: 10px;\n  margin-right: auto;\n  margin-left: auto;\n  max-width: 1200px;\n  gap: var(--std-large-size-inter-element)\n}\n.issues-filters .bs-input-text-like{\n  height: 27px;\n}\n.issues-filters>*{\n  min-width: 180px;\n}\n.issues-filters-name{\n  display: flex;\n}\n.issues-filters-name .bs-button-icon{\n  height: 27px;\n  width: 27px;\n  font-size: 27px;\n  background-color: #e6ded5;\n}\n.issues-filters-name .bs-button-icon,\n.issues-filters-name .bs-button-icon>div{\n  border-radius: 3px 0px 0px 3px;\n}\n.issues-filters-name .bs-input-text{\n  border-radius: 0px 3px 3px 0px;\n  border-width: 1px 1px 1px 0px;\n}\n.issues-filters-name .bs-input-text,\n.issues-filters .bs-select-display-default{\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
