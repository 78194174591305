import React, { useState }  from "react";
import T                    from '@cBehaviour/i18n'; 
import DropDown             from '@cComponents/dropDown';
import Release              from "@cEntities/release";
import Display              from "@cComponents/displayIf";
import useHelper            from "@universal/behaviour/hooks/useHelper";

export default function() {
  const [displayRelease, setDisplayRelease] = useState(false);
  const assistance = useHelper("assistance");
  const [assistanceEmail, setAssistanceEmail] = React.useState();
  React.useEffect(() => {
    assistance.getSupportUri().then((uri) => setAssistanceEmail(uri));
  }, [assistance]);

  return (
    <>
      <DropDown.List alignRight={ true }>
        <DropDown.List.Main>
        <img 
          alt="BetterStreet" 
          src={'/images/ico-help.png'} style={{ 
          width: "25px",
          height: "25px"
        }}/>
        </DropDown.List.Main>
        <DropDown.List.List>
          <div>
            <a href={ assistanceEmail }  rel="noopener noreferrer" target="_blank">
              <span className="fa fa-envelope"/>
              <span>&nbsp;<T>send_message</T></span>
            </a>
          </div>          
          <div onClick={() => setDisplayRelease(true) }>
            <span className="fa fa-gitlab"/>
            <span>&nbsp;<T>release_note</T></span>
          </div>
        </DropDown.List.List>
      </DropDown.List>
      <Display.If  condition={displayRelease}>
      {() => (
        <Release.List application="citizen" close={() => { setDisplayRelease(false); }}/>
      )}
      </Display.If>
    </>
  )
}