// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-scrollBar-scrollable-manager {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.bs-scrollBar-scrollable-manager-content{
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.bs-scrollBar-scrollable-manager-scrollbar{
  height: 100%;
  overflow-y: auto;
}
.bs-scrollBar-scrollable-manager-scrollbar-fake {
  width: 1px;
}

.bs-scrollBar-scrollable-container { 
  position: relative;
  overflow: hidden;
  height: 100%;
}

.bs-scrollBar-scrollable-viewPort { 
  position: absolute;
  min-height: 100%;
  width: 100%;
  display: flex;
}

.bs-scrollBar-scrollable-viewPort-container {
  flex-basis: 0;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./../common/components/scrollBar/scrollable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".bs-scrollBar-scrollable-manager {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n}\n\n.bs-scrollBar-scrollable-manager-content{\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n  height: 100%;\n}\n\n.bs-scrollBar-scrollable-manager-scrollbar{\n  height: 100%;\n  overflow-y: auto;\n}\n.bs-scrollBar-scrollable-manager-scrollbar-fake {\n  width: 1px;\n}\n\n.bs-scrollBar-scrollable-container { \n  position: relative;\n  overflow: hidden;\n  height: 100%;\n}\n\n.bs-scrollBar-scrollable-viewPort { \n  position: absolute;\n  min-height: 100%;\n  width: 100%;\n  display: flex;\n}\n\n.bs-scrollBar-scrollable-viewPort-container {\n  flex-basis: 0;\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
