// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-update-password{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--std-size-inter-element);
  padding: var(--std-padding)
}
.bs-update-password-title {
  text-align: center;
  padding-bottom: var(--std-padding);
}
.bs-update-password .bs-nudge{
  font-size: 13px;
}

.bs-update-password-form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bs-update-password-confirm-password-cerror-like {
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./../common/entities/user/updatePassword.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kCAAkC;EAClC;AACF;AACA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;AACA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".bs-update-password{\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: var(--std-size-inter-element);\n  padding: var(--std-padding)\n}\n.bs-update-password-title {\n  text-align: center;\n  padding-bottom: var(--std-padding);\n}\n.bs-update-password .bs-nudge{\n  font-size: 13px;\n}\n\n.bs-update-password-form{\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.bs-update-password-confirm-password-cerror-like {\n  padding: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
