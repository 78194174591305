// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-cover{
  position: relative;
  width: fit-content;
  margin: 0px;
  width: 100%;
  min-height: 37px;
}
.bs-issue-cover>.bs-file>img{
  width:100%;
}`, "",{"version":3,"sources":["webpack://./src/entities/issue/cover.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ","sourcesContent":[".bs-issue-cover{\n  position: relative;\n  width: fit-content;\n  margin: 0px;\n  width: 100%;\n  min-height: 37px;\n}\n.bs-issue-cover>.bs-file>img{\n  width:100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
