import React  from "react";
import Issue  from "@entities/issue";
import Filter from "@cComponents/filter";


import "./list.css";

export default (props) => (
  <div className="bs-issues">
    <div className="bs-issues-filter">
      <Issue.Filter noMerged/>
    </div>
    <Filter.Subject>
    {(composeQuery) => (
      <div className="bs-issues-list">
        <Issue.List query={ composeQuery() }/>
      </div>
    )}
    </Filter.Subject>
  </div>
);