// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
}

.bs-old-input-number .bs-old-input-number-button {
  background-color: #fa7251;
  transition: all 0.3s ease-out;
  opacity: 0.9;
  font-size: 1.2em;
  padding: 2px 11px;
  border-radius: 3px;
  font-weight: bold;
}

.bs-old-input-number .bs-old-input-number-button:hover {
  opacity: 1;
} 

.bs-old-input-number input {
  width: 70px;
  text-align: center;
}
/* Suppression des flèches sur Chrome, Safari, Edge */
.bs-old-input-number input::-webkit-outer-spin-button,
.bs-old-input-number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.bs-old-input-number input[type=number] {
    -moz-appearance:textfield; 
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/number.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA,qDAAqD;AACrD;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,YAAY;AACZ;IACI,yBAAyB;AAC7B","sourcesContent":[".bs-old-input-number {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 150px;\n}\n\n.bs-old-input-number .bs-old-input-number-button {\n  background-color: #fa7251;\n  transition: all 0.3s ease-out;\n  opacity: 0.9;\n  font-size: 1.2em;\n  padding: 2px 11px;\n  border-radius: 3px;\n  font-weight: bold;\n}\n\n.bs-old-input-number .bs-old-input-number-button:hover {\n  opacity: 1;\n} \n\n.bs-old-input-number input {\n  width: 70px;\n  text-align: center;\n}\n/* Suppression des flèches sur Chrome, Safari, Edge */\n.bs-old-input-number input::-webkit-outer-spin-button,\n.bs-old-input-number input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Firefox */\n.bs-old-input-number input[type=number] {\n    -moz-appearance:textfield; \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
