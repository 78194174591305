// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-button-icon {
  position: relative;
  height: 30px;
  width: 30px;
  font-size: 30px;
  background-color: rgba(255, 255, 255, 0.9);
}
.bs-button-icon-circle,
.bs-button-icon-circle>div{
  border-radius: 50%;
}
.bs-button-icon:hover{
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.bs-button-icon>div{
  height: 100%;
  width: 100%;
  font-size: 0.67em;
  display: flex;
  box-shadow: 0px 0px 4px 0px black;
}
.bs-button-icon:hover>div{
  box-shadow: 0px 0px 4px 0px #3d3c3c;
}
.bs-button-icon:active.bs-button-pushed>div,
.bs-button-icon.bs-button-pushed>div{
  box-shadow: inset 0px 0px 3px 0px black;
}
.bs-button-icon:active:hover.bs-button-pushed>div,
.bs-button-icon:hover.bs-button-pushed>div{
  box-shadow: inset 0px 0px 3px 0px #3d3c3c;
}
.bs-button-icon.bs-color-green {
  color: #27ae60;;
}

.bs-button-icon.bs-color-red {
  color: #E84C24;
}

.bs-button-icon.bs-color-grey {
  color: #999;;
}

.bs-button-icon>div>span {
  margin:auto;
}`, "",{"version":3,"sources":["webpack://./../common/components/button/icon.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,0CAA0C;AAC5C;AACA;;EAEE,kBAAkB;AACpB;AACA;EACE,wCAAwC;EACxC,eAAe;AACjB;AACA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,iCAAiC;AACnC;AACA;EACE,mCAAmC;AACrC;AACA;;EAEE,uCAAuC;AACzC;AACA;;EAEE,yCAAyC;AAC3C;AACA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".bs-button-icon {\n  position: relative;\n  height: 30px;\n  width: 30px;\n  font-size: 30px;\n  background-color: rgba(255, 255, 255, 0.9);\n}\n.bs-button-icon-circle,\n.bs-button-icon-circle>div{\n  border-radius: 50%;\n}\n.bs-button-icon:hover{\n  background-color: rgba(255, 255, 255, 1);\n  cursor: pointer;\n}\n.bs-button-icon>div{\n  height: 100%;\n  width: 100%;\n  font-size: 0.67em;\n  display: flex;\n  box-shadow: 0px 0px 4px 0px black;\n}\n.bs-button-icon:hover>div{\n  box-shadow: 0px 0px 4px 0px #3d3c3c;\n}\n.bs-button-icon:active.bs-button-pushed>div,\n.bs-button-icon.bs-button-pushed>div{\n  box-shadow: inset 0px 0px 3px 0px black;\n}\n.bs-button-icon:active:hover.bs-button-pushed>div,\n.bs-button-icon:hover.bs-button-pushed>div{\n  box-shadow: inset 0px 0px 3px 0px #3d3c3c;\n}\n.bs-button-icon.bs-color-green {\n  color: #27ae60;;\n}\n\n.bs-button-icon.bs-color-red {\n  color: #E84C24;\n}\n\n.bs-button-icon.bs-color-grey {\n  color: #999;;\n}\n\n.bs-button-icon>div>span {\n  margin:auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
