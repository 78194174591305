// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-field{
  display: block;
}
.bs-old-field.bs-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.bs-old-field .bs-old-field-label {
  margin-bottom: 5px;
  color: #2c3e50;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/field.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".bs-old-field{\n  display: block;\n}\n.bs-old-field.bs-disabled {\n  pointer-events: none;\n  opacity: 0.6;\n}\n\n.bs-old-field .bs-old-field-label {\n  margin-bottom: 5px;\n  color: #2c3e50;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
