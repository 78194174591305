// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issue-item-title-category{
  display: flex;

}
.issue-item-title-category>img{
    position: relative;
    top: 2px;
    max-height: 14px;
}
.issue-item-title-category>span{
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 2;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/entities/issue/category.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;AAEf;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".issue-item-title-category{\n  display: flex;\n\n}\n.issue-item-title-category>img{\n    position: relative;\n    top: 2px;\n    max-height: 14px;\n}\n.issue-item-title-category>span{\n    padding-left: 4px;\n    padding-right: 4px;\n    font-weight: 700;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    flex-shrink: 2;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
