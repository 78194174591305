// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-hour{
  display: flex;
  flex-direction: row;
  position: relative;
}
.bs-input-hour>div{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.bs-input-hour>div>div:nth-child(1){
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  padding-left: 19px;
}
.bs-input-hour>div>div:nth-child(1)>input{
  font-size: 16px;
  width: 73px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
}
.bs-input-hour>div>div:nth-child(2){
  z-index: 1;
  background-color: white;
  position: absolute;
  top: 100%;
  width: 100%;
  box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  max-height: 190px;
  overflow: hidden;
}
.bs-input-hour .bs-scrollBar{
  overflow-y: scroll;
    max-height: inherit;
}
.bs-input-hour-select-value{
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bs-input-hour-select-value:hover{
  cursor: pointer;
  background-color:rgba(82,168,236,0.8);
}`, "",{"version":3,"sources":["webpack://./../common/components/input/hour.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,WAAW;EACX,wBAAwB;EACxB,0BAA0B;EAC1B,yBAAyB;EACzB,2BAA2B;EAC3B,aAAa;AACf;AACA;EACE,UAAU;EACV,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,+CAA+C;EAC/C,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,kBAAkB;IAChB,mBAAmB;AACvB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,qCAAqC;AACvC","sourcesContent":[".bs-input-hour{\n  display: flex;\n  flex-direction: row;\n  position: relative;\n}\n.bs-input-hour>div{\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 1;\n}\n.bs-input-hour>div>div:nth-child(1){\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-bottom: 1px solid;\n  padding-left: 19px;\n}\n.bs-input-hour>div>div:nth-child(1)>input{\n  font-size: 16px;\n  width: 73px;\n  border-top-style: hidden;\n  border-right-style: hidden;\n  border-left-style: hidden;\n  border-bottom-style: hidden;\n  outline: none;\n}\n.bs-input-hour>div>div:nth-child(2){\n  z-index: 1;\n  background-color: white;\n  position: absolute;\n  top: 100%;\n  width: 100%;\n  box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, 0.2);\n  max-height: 190px;\n  overflow: hidden;\n}\n.bs-input-hour .bs-scrollBar{\n  overflow-y: scroll;\n    max-height: inherit;\n}\n.bs-input-hour-select-value{\n  height: 36px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n.bs-input-hour-select-value:hover{\n  cursor: pointer;\n  background-color:rgba(82,168,236,0.8);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
