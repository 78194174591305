// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-myProfile{
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}
.bs-myProfile>div:nth-child(n + 2){
  margin-top: 20px;
}

.bs-myProfile>* {
  background-color: white;
  box-shadow: 0px 1px 8px 0px #c4c4c4;
  padding: 10px 10px;
}
.bs-myProfile-send{
  margin-top: 10px;
}
.bs-myProfile-button {
  display: flex;
  justify-content: center;
}
.bs-myProfile-button>button {
  width: 283.7px;
}
.bs-myProfile-others-content {
  display: flex;
}
.bs-myProfile-others-content>*{
  flex-basis: 0;
  flex-grow: 1;
}

.bs-myProfile-modal{
  margin: 10px;
}
.bs-myProfile-modal>*{
  margin: 10px 0px;
}
.bs-deleteAccount-button{
  display: flex;
}
.bs-deleteAccount-button>*{
  flex-grow: 1;
}
.bs-deleteAccount-button>*:first-child{
  margin-right: 3px;
}
.bs-deleteAccount-button>*:last-child{
  margin-left: 3px;
}`, "",{"version":3,"sources":["webpack://./src/applications/views/users/me.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,mCAAmC;EACnC,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-myProfile{\n  width: 800px;\n  margin-left: auto;\n  margin-right: auto;\n  padding-top: 10px;\n}\n.bs-myProfile>div:nth-child(n + 2){\n  margin-top: 20px;\n}\n\n.bs-myProfile>* {\n  background-color: white;\n  box-shadow: 0px 1px 8px 0px #c4c4c4;\n  padding: 10px 10px;\n}\n.bs-myProfile-send{\n  margin-top: 10px;\n}\n.bs-myProfile-button {\n  display: flex;\n  justify-content: center;\n}\n.bs-myProfile-button>button {\n  width: 283.7px;\n}\n.bs-myProfile-others-content {\n  display: flex;\n}\n.bs-myProfile-others-content>*{\n  flex-basis: 0;\n  flex-grow: 1;\n}\n\n.bs-myProfile-modal{\n  margin: 10px;\n}\n.bs-myProfile-modal>*{\n  margin: 10px 0px;\n}\n.bs-deleteAccount-button{\n  display: flex;\n}\n.bs-deleteAccount-button>*{\n  flex-grow: 1;\n}\n.bs-deleteAccount-button>*:first-child{\n  margin-right: 3px;\n}\n.bs-deleteAccount-button>*:last-child{\n  margin-left: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
