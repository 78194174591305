import React          from "react";
import T              from "@cBehaviour/i18n";
import Form           from "@cBehaviour/form";
import Input          from "@cComponents/old/input";
import Button         from "@cComponents/button";

import "./session.css";
import "./forgotPassword.css";
export default class ForgotPassword extends React.Component{
  render(){
    return (
      <div className="bs-container bs-forgot-password">
        <div className="bs-content bs-box">
          <div className="bs-box-title">
            <h2><T>reinitialize_password</T></h2>
          </div>
          <div className="e">
            <Form.Simple name="reinitialize_password" submit={ this.props.handler }>
              {(object, errors, form) => (
                <>
                  <label className="bs-txt-content">
                    <T>email</T>
                    <Input.Text name="email" />
                  </label>
                  <div className="bs-txt-content">
                    <Button.Text onClick={ () => { form.submit(); }}>
                      <T>reinitialize_password</T>
                    </Button.Text>
                  </div>
                </>
              )}
            </Form.Simple>
          </div>
        </div>
      </div>
    )
  }
}