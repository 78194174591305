// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-location-edit {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.bs-old-input-location-edit > *:nth-child(n +2) {
  margin-top: 10px;
}
.bs-old-input-location{
  position: relative;
}
.bs-old-input-location-address{
  color: #27ae60;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 2px;
  border-radius: 2px;
}
.bs-old-input-location-address-text{
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/location.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,0CAA0C;EAC1C,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-old-input-location-edit {\n  height: 100%;\n  width: 100%;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n.bs-old-input-location-edit > *:nth-child(n +2) {\n  margin-top: 10px;\n}\n.bs-old-input-location{\n  position: relative;\n}\n.bs-old-input-location-address{\n  color: #27ae60;\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  padding: 2px;\n  background-color: rgba(255, 255, 255, 0.8);\n  margin: 2px;\n  border-radius: 2px;\n}\n.bs-old-input-location-address-text{\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
