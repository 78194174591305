// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-user{
  width: 100%;
}
.bs-form-user>*:nth-child(n+2){
  margin-top: 10px;
}
.bs-form-user-identify{
  width: 100%;
  display: flex;
}
.bs-form-user-identify>*{
  flex-grow: 1;
}
.bs-form-user-followedByDefault>div:first-child{
  display:flex;
}
.bs-form-user-followedByDefault>div:last-child{
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/entities/users/form.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;AACjB","sourcesContent":[".bs-form-user{\n  width: 100%;\n}\n.bs-form-user>*:nth-child(n+2){\n  margin-top: 10px;\n}\n.bs-form-user-identify{\n  width: 100%;\n  display: flex;\n}\n.bs-form-user-identify>*{\n  flex-grow: 1;\n}\n.bs-form-user-followedByDefault>div:first-child{\n  display:flex;\n}\n.bs-form-user-followedByDefault>div:last-child{\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
