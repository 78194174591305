// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-button-header {
  color: #999;
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 100%;
  position: relative;
}

.bs-button-header:hover {
  cursor: pointer;
}

.bs-button-header .bs-text{
  font-size: 0.9em;
}

@media screen and (max-width: 1550px) {
  .bs-button-header .bs-text{
    display: none;
  }

  .bs-button-header:hover .bs-text{ 
    display:block; 
    position: absolute;
    background-color:rgba(250, 114, 79, 0.80);
    border-radius:2px;
    bottom: -15px;
    left: 40px;
    color: white;
    border: 1px solid grey;
    padding: 2px;
    z-index: 1;
    font-size: 0.8em;
  }

  .bs-button-header {
    padding: 0 24px;
  }
}

.bs-button-header>*:nth-child(2){
  padding-left: 6px;
}

.bs-button-header.bs-selected,
.bs-button-header:hover {
  background-color: rgba(232, 76, 36, 0.05);
  border-bottom: 3px solid #E84C24;
}`, "",{"version":3,"sources":["webpack://./../common/components/button/header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,+CAA+C;EAC/C,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,iBAAiB;IACjB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,yCAAyC;EACzC,gCAAgC;AAClC","sourcesContent":[".bs-button-header {\n  color: #999;\n  border-bottom: 3px solid rgba(255, 255, 255, 0);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 12px;\n  height: 100%;\n  position: relative;\n}\n\n.bs-button-header:hover {\n  cursor: pointer;\n}\n\n.bs-button-header .bs-text{\n  font-size: 0.9em;\n}\n\n@media screen and (max-width: 1550px) {\n  .bs-button-header .bs-text{\n    display: none;\n  }\n\n  .bs-button-header:hover .bs-text{ \n    display:block; \n    position: absolute;\n    background-color:rgba(250, 114, 79, 0.80);\n    border-radius:2px;\n    bottom: -15px;\n    left: 40px;\n    color: white;\n    border: 1px solid grey;\n    padding: 2px;\n    z-index: 1;\n    font-size: 0.8em;\n  }\n\n  .bs-button-header {\n    padding: 0 24px;\n  }\n}\n\n.bs-button-header>*:nth-child(2){\n  padding-left: 6px;\n}\n\n.bs-button-header.bs-selected,\n.bs-button-header:hover {\n  background-color: rgba(232, 76, 36, 0.05);\n  border-bottom: 3px solid #E84C24;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
