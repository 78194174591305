import React          from "react";
import { Link }       from "@cFeatures/router";
import T              from "@cBehaviour/i18n";
import Facebook       from "@components/facebook";
import Form           from "@cBehaviour/form";
import Input          from "@cComponents/old/input";
import Button         from "@cComponents/button";
import Display        from "@cComponents/displayIf";
import FormError      from "@cComponents/formError";
import Application    from "@uBehaviour/application";

import "./session.css";
import "./login.css";

class Login extends React.Component{
  constructor(props){
    super(props);
    this._loginForm = React.createRef();
  }
  render(){
    return (
      <div className="bs-login bs-container">
        <div>
          <Display.If condition={ this.props.microstore.has("login_info") }>
          {() => (             
            <div className="bs-content">
              <div className="bs-info">
                <span className="fa fa-info" />
                <span><T>{ this.props.microstore.get("login_info") }</T></span>
              </div>
            </div>
          )}
          </Display.If>
          <Display.If condition={ this.props.private }>
            <div className="bs-content">
              <div className="bs-warning">
                <span className="fa fa-warning" />
                <span><T>login_to_use</T></span>
              </div>
            </div>
          </Display.If>
          <div className="bs-content bs-login-main">
            <div className="bs-box">
              <div className="bs-box-title">
                <h2><T>login</T></h2>
              </div>
              <div className="bs-box-content bs-column bs-connect">
                <div className="bs-connect-fb">
                  <h3><T>login_with_facebook</T></h3>
                  <Facebook
                    appId={ this.props.configuration.get("facebook_app_id") }
                    language={ this.props.i18n.currentLanguage.iso }
                    submit={ user => this.props.session.login(user).then(() => this.props.onLogin ? this.props.onLogin() : null ) }
                  >
                    <Facebook.Login />
                  </Facebook>
                  <p className="bs-txt-content"><T>login_facebook_be_sure</T></p>                    
                </div>
                <div className="bs-connect-bs">
                  <h3><T>login_with_bs</T></h3>
                  <div>
                    <Form.Simple ref={ this._loginForm } name="login" submit={ value => this.props.session.login(value.email, value.password).then(() => this.props.onLogin ? this.props.onLogin() : null ) }>
                      <FormError>
                        <label className="bs-txt-content">
                          <T>email</T>
                          <Input.Text name="email" />
                        </label>
                        <label className="bs-txt-content">
                          <T>password</T>
                          <Input.Password name="password" />
                        </label>
                      </FormError>
                    </Form.Simple>
                  </div>
                  <div className="bs-txt-content">
                    <Button.Text onClick={ () => { this._loginForm.current.submit() }}>
                      <T>login</T>
                    </Button.Text>
                  </div>
                  <div className="bs-txt-content bs-txt-right bs-btn-forgot-password">
                    <Link to="/forgot_password" fromRoot >
                      <T>forgot_password</T>
                    </Link>
                  </div>
                  <div className="bs-txt-content bs-txt-right bs-btn-create-account">
                    <Link to="/create_account" fromRoot >
                      <T>create_free_account</T>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bs-content">
            <a href={ this.props.configuration.get("admin_url") }>
              <div className="bs-box bs-box-admin">
                <span className="fa fa-university" />
                <span><T>admin_login</T></span>
                <span className="fa fa-angle-right" />
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default Application.Service.forward(["microstore", "session", "i18n", "configuration"], Login);