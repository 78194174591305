import React  from 'react';
import File   from '@cComponents/file';
import State  from '@cEntities/issues/components/state';

import './cover.css';

const Cover = ({ file, fit, height, width, state }) => (
  <State.Wrapper state={ state }>
    <div className="bs-issue-cover">
      <File file={ file } fit={ fit ? fit : "scale" } height={ height } width={ width }/>
    </div>
  </State.Wrapper>
);

export default Cover;