import React, { useState }  from "react";
import T                    from "@cBehaviour/i18n";
import Users                from "@entities/users";
import Button               from "@cComponents/button";
import Display              from "@cComponents/displayIf";
import Modal                from "@cComponents/modal";
import RenewPassword        from "@cEntities/user/renewPassword";
import Title                from "@cComponents/title";
import Nudge                from "@cComponents/nudge";
import Application          from "@uBehaviour/application";

import Subscribe from "@common/entities/subscription/components/subscribe";

import "./me.css";

const DeleteAccount = (props) => (
  <>
    <Title><T>delete_account_title</T></Title>
    <div className="bs-deleteAccount bs-myProfile-modal">
      <h3><T>delete_account_confirmation</T>?</h3>
      <Nudge type="warning"><T>delete_account_irreversible</T></Nudge>
      <div>
        <div className="bs-deleteAccount-button">
          <Button.Text onClick={ props.close }><T>delete_account_cancel</T></Button.Text>
          <Button.Text onClick={ () => props.session.deleteAccount() }><T>delete_account_confirm</T></Button.Text>
        </div>
      </div>
    </div>
  </>
)

const Gprd = (props) => {
  const [displayCancelMyAccount, setDisplayCancelMyAccount] = useState(false);
  const ref = React.createRef();
  return (
    <>
      <Title><T>personal_data_title</T></Title>
      <div className="bs-rgpd bs-myProfile-modal">
        <h3><T>personal_data_nature</T>:</h3>
        <Nudge type="info">
          <T bind={ props.user }>personal_data_email</T><br/>
          <T bind={ props.user }>personal_data_firstname</T>< br/>
          <T bind={ props.user }>personal_data_lastname</T>
        </Nudge>
        <div><T>personal_data_delete</T>.</div>
        <div className="bs-rgpd-cancelMyAccount bs-myProfile-button">
          <Button.Text onClick={ () => setDisplayCancelMyAccount(true) }><T>cancel_my_account</T></Button.Text>
        </div>
      </div>      
      <Display.If condition={displayCancelMyAccount}>
        <Modal.Show close={ () => setDisplayCancelMyAccount(false) } style={{ "width": "800px" }} ref={ref}>
          <DeleteAccount user={props.user} close={() => ref.current.close() } session={ props.session }/>
        </Modal.Show>
      </Display.If>
    </>
  )
}
const Me = (props) => {
  const [displayRenewPassword, setDisplayRenewPassword]   = useState(false);
  const [displayRGPD, setDisplayRGPD]                     = useState(false);
  const formRef = React.createRef();
  return (
    <>
      <div className="bs-myProfile">
        <div className="bs-myProfile-form">
          <div>
            <h2><T>my_profile</T></h2>
          </div>
          <div>
            <Users.Form ref={formRef} datas={{ 
              firstname: props.session.user.firstname, 
              lastname: props.session.user.lastname, 
              email: props.session.user.email,
            }}/>
          </div>
          <div className="bs-myProfile-send bs-myProfile-button">
            <Button.Text onClick={() => formRef.current.submit() }>
              <T>send</T>
            </Button.Text>
          </div>
        </div>
        <div>
          <Users.Subscription />
        </div>
        <div className="bs-myProfile-others">
          <h2><T>my_profile_others</T></h2>
          <div className="bs-myProfile-others-content">
            <div className="bs-myProfile-updatePassword bs-myProfile-button">
              <Button.Text onClick={ () => setDisplayRenewPassword(true)}><T>renew_password</T></Button.Text>
            </div>
            <div className="bs-myProfile-receiveRgpd bs-myProfile-button">
              <Button.Text onClick={ () => setDisplayRGPD(true) }><T>receive_rgpd</T></Button.Text>
            </div>
          </div>
        </div>
      </div>
      <Display.If condition={displayRenewPassword}>
        <Modal.Show close={ () => setDisplayRenewPassword(false) } style={{ "width": "800px" }}>
          <RenewPassword onClose={() => setDisplayRenewPassword(false) }/>
        </Modal.Show>
      </Display.If>
      <Display.If condition={displayRGPD}>
        <Modal.Show close={ () => setDisplayRGPD(false) } style={{ "width": "800px" }}>
          <Gprd user={ props.session.user } session={ props.session }/>
        </Modal.Show>
      </Display.If>
    </>
  );
}


export default Application.Service.forward(["session"], Me);