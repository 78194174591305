import React       from "react";
import Application from "@uBehaviour/application";
import DropDown    from '@cComponents/dropDown';

export default (props) => React.createElement(Application.Service, { name: ["i18n"] }, ([i18n]) => {
  return (
    <DropDown.List alignRight={ true }>
      <DropDown.List.Main>
        <div>{ i18n.currentLanguage.label }</div>
      </DropDown.List.Main>
      <DropDown.List.List>
        {i18n.acceptedLanguages.filter(l => l.iso !== i18n.currentLanguage.iso).map(language => (
          <div onClick={() => i18n.currentLanguage = language }>
            <span>{language.label}</span>
          </div>
        ))}
      </DropDown.List.List>
    </DropDown.List>
  )});