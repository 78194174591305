// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-create-account{
  height: 100%;
  min-height: 525px;
}
.bs-create-account > .bs-content {
  width: 370px;
  padding: 12px;
}
.bs-create-account .bs-txt-content {
  font-size: 14px;
}
.bs-create-account .bs-button{
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.bs-condition {
  font-size: 13px;
}
.bs-condition  .bs-link{
  text-decoration: underline;
  cursor: pointer;
  color: #aaa;
}`, "",{"version":3,"sources":["webpack://./src/entities/sessions/createAccount.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,0BAA0B;EAC1B,eAAe;EACf,WAAW;AACb","sourcesContent":["\n.bs-create-account{\n  height: 100%;\n  min-height: 525px;\n}\n.bs-create-account > .bs-content {\n  width: 370px;\n  padding: 12px;\n}\n.bs-create-account .bs-txt-content {\n  font-size: 14px;\n}\n.bs-create-account .bs-button{\n  width: 100%;\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n.bs-condition {\n  font-size: 13px;\n}\n.bs-condition  .bs-link{\n  text-decoration: underline;\n  cursor: pointer;\n  color: #aaa;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
