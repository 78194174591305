import React    from "react";
import { Link } from "@cFeatures/router";
import Social   from "@entities/social";
import T        from "@cBehaviour/i18n";
import Scrollbar  from "@cComponents/scrollBar";
import Stylized from "@cComponents/button/stylized.tsx"
import "./index.css";

export default class Index extends React.Component{
  render(){
    return (
      <Scrollbar>
        <div className="bs-index">
          <div className="bs-entrance">
            <div className="bs-entrance-element bs-entrance-citizen">
              <div className="bs-entrance-element-header" />
              <div className="bs-entrance-element-content">
                <h2><T>index_citizen_title</T></h2>
                <p><T>index_citizen_content</T></p>
                <Link to="/citizen" fromRoot>
                  <Stylized.Text>
                    <T>index_citizen_button</T>
                  </Stylized.Text>
                </Link>
              </div>
            </div>
            <div className="bs-entrance-element bs-entrance-commune">
              <div className="bs-entrance-element-header" />
              <div className="bs-entrance-element-content">
                <h2><T>index_commune_title</T></h2>
                <p><T>index_commune_content</T></p>

                <Link to="/about" fromRoot>
                  <Stylized.Text>
                    <T>index_commune_button</T>
                  </Stylized.Text>
                </Link>
              </div>
            </div>
            <div className="bs-entrance-logo">
              <img alt="BetterStreet" src={`/images/logos/betterstreet_color.svg`} />
            </div>
          </div>
          < Social />
        </div>
      </Scrollbar>
    );
  }
}