import React, { FunctionComponent } from 'react';
import UpdatePasswordComponent      from '@cEntities/user/updatePassword';
import useService                   from '@uBehaviour/hooks/useService';
import SessionService               from '@uServices/session';
import "./updatePassword.css";

const UpdatePassword: FunctionComponent = () => {
  const session = useService<SessionService>('session');

  const updateMyPassword = React.useCallback(( password: string ) => {
    return session.updateMyPassword(password);
  }, [session]);

  return (
    <div className='bs-citizen-updatePassword'>
      <UpdatePasswordComponent updatePassword={ updateMyPassword }/>
    </div>
  );
};

export default UpdatePassword;