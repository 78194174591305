// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../citizen/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-nudge{
  display: flex;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-radius: 5px;
}
.bs-nudge.bs-nudge-warning{
  border-color: rgba(250, 114, 81, 0.3);
  background-color: rgba(250, 114, 81, 0.1);
}
.bs-nudge.bs-nudge-info{
  border-color: rgba(81, 133, 210, 0.3);
  background-color: rgba(81, 133, 210, 0.1);
}
.bs-nudge>div{
  padding: 10px;
}
.bs-nudge.bs-nudge-info>div:first-child{
  color: rgba(81, 133, 210, 1);
}
.bs-nudge.bs-nudge-warning>div:first-child{
  color: rgba(250, 114, 81, 1);
}
.bs-nudge>div:first-child{
  font-size: 40px;
}
.bs-nudge>div:last-child{
  flex-grow: 1;
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./../common/components/nudge.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,qCAAqC;EACrC,yCAAyC;AAC3C;AACA;EACE,qCAAqC;EACrC,yCAAyC;AAC3C;AACA;EACE,aAAa;AACf;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,eAAe;AACjB;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".bs-nudge{\n  display: flex;\n  border-width: 1px;\n  border-style: solid;\n  align-items: center;\n  border-radius: 5px;\n}\n.bs-nudge.bs-nudge-warning{\n  border-color: rgba(250, 114, 81, 0.3);\n  background-color: rgba(250, 114, 81, 0.1);\n}\n.bs-nudge.bs-nudge-info{\n  border-color: rgba(81, 133, 210, 0.3);\n  background-color: rgba(81, 133, 210, 0.1);\n}\n.bs-nudge>div{\n  padding: 10px;\n}\n.bs-nudge.bs-nudge-info>div:first-child{\n  color: rgba(81, 133, 210, 1);\n}\n.bs-nudge.bs-nudge-warning>div:first-child{\n  color: rgba(250, 114, 81, 1);\n}\n.bs-nudge>div:first-child{\n  font-size: 40px;\n}\n.bs-nudge>div:last-child{\n  flex-grow: 1;\n  text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
